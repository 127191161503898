import React, { Dispatch, SetStateAction, useEffect, useState, useMemo } from 'react';
import { Table } from '@efilecabinet/nautilus-ui';
import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import { TranslationFiles, useSafeTranslation, MDFieldsTKeys, MetadataTKeys } from '../../../../hooks/useSafeTranslation';
import { useAuthContext } from '../../../../app/_context/AuthContext';
import { useMDFieldApi } from '../../../../api/mdFields/useMDFieldApi';
import { MDManagementZeroState } from '../../_management/MdManagementZeroState/MdManagementZeroState';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { MDFieldModal } from '../_modal/MdFieldModal/MdFieldModal';
import { MetadataFieldDto, MDFieldTypes, MetadataFieldDecorDto } from '../../../../api/mdFields/mdFieldApiTypes';
import { useMetadataFieldUtilities } from '../../../../hooks/metadata/useMetadataFieldUtilities';

export interface MdFieldsManagementTableProps {
    active: boolean;
    refreshFieldsSentinalKeyState: [number, Dispatch<SetStateAction<number>>];
    selectedFieldState: [MetadataFieldDto, Dispatch<SetStateAction<MetadataFieldDto>>];
}

export const MdFieldsManagementTable = ({ active, selectedFieldState : [selectedField, setSelectedField], refreshFieldsSentinalKeyState : [refreshFieldsSentinalKey, setRefreshFieldsSentinalKey] }: MdFieldsManagementTableProps) => {

    const { t } = useSafeTranslation(TranslationFiles.MDFields);

    const { authUser, hasAuthUser } = useAuthContext();
    const { deleteMdFieldAsync } = useMDFieldApi();
    const { loadAllMdAccountFieldsAsync } = useMetadataFieldUtilities();
    const { openModal } = useLayerContext();

    const [accountFields, setAccountFields] = useState<MetadataFieldDecorDto[]>();

    const rowData: MetadataFieldDecorDto[] | null = useMemo(() => {
        if (!accountFields) {
            return null;
        } else {
            return accountFields;
        }
    }, [accountFields]);

    const columnDefs: ColDef<MetadataFieldDecorDto>[] = useMemo(() => {
        return [
            {
                field: 'name',
                headerName: t(MDFieldsTKeys.NameColumn),
                flex: 3
            },
            {
                field: 'type',
                headerName: t(MDFieldsTKeys.TypeColumn),
                flex: 1,
                valueFormatter: ({ value }) => (MDFieldTypes[value] ?? ''),
            },
            {
                headerName: t(MDFieldsTKeys.InstanceColumn),
                flex: 2,
                type: 'numericColumn',
                // TODO: Implement instance count
                valueGetter: () => (0),
            },
            {
                field: 'createdByDisplayName',
                headerName: t(MDFieldsTKeys.CreatedByColumn),
                flex: 2,
                valueFormatter: ({ value }) => (value ?? '-'),
            },
            // TODO: Implement context menu
        ];
    }, []);

    function openFieldModal(field?: MetadataFieldDecorDto) {
        openModal(() => <MDFieldModal mdFieldToEdit={field} onSubmitField={() => setRefreshFieldsSentinalKey(prevState => prevState + 1)} />);
    }

    function onEdit(field: MetadataFieldDecorDto) {
        openFieldModal(field);
    }

    function onDelete(field: MetadataFieldDecorDto) {
        setAccountFields(accountFields?.filter((f) => f.id !== field.id));
        deleteMdFieldAsync(field.id);
    }

    function onRowSelected(event: RowSelectedEvent<MetadataFieldDecorDto>) {
        const rowData = event.data;
        if (!!rowData && event.node.isSelected()) {
            setSelectedField(rowData);
        }
    }

    useEffect(() => {
        if (!!active && !!authUser) {
            loadAllMdAccountFieldsAsync(authUser.accountID).then((fields) => setAccountFields(fields));
        }
    }, [hasAuthUser, refreshFieldsSentinalKey, active]);

    return (
        <>
            {/* TODO: When we implement custom zero states for the Nautilus table, move the zero state into the Table */}
            {rowData?.length === 0
                ? <MDManagementZeroState buttonText={t(MetadataTKeys.CreateField)} onButtonClick={() => openFieldModal()} />
                : <Table<MetadataFieldDecorDto>
                    height='600px'
                    columnDefs={columnDefs}
                    rowData={rowData}
                    onRowSelected={onRowSelected}
                    showSearchBar={true}
                    rowSelection={{ mode: 'singleRow' }} />
            }
        </>
    );
};