import React from 'react';
import { Row, Col } from 'reactstrap';
import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from '@efilecabinet/nautilus-ui';
import { Text } from '@efilecabinet/efc-atlantis-components';
import '../../__shared/MdLibraryModal.css';

export interface MDLibraryMainCardProps<R> {
    title: string;
    rowData: R[] | null;
    columnDefs: ColDef<R>[];
    onRowSelected: (event: RowSelectedEvent<R>) => void;
}

export const MDLibraryMainCard = <R,> ({ title, rowData, columnDefs, onRowSelected }: MDLibraryMainCardProps<R>) => {

    return (
        <Col className='h-100 library-modal-body-card-left'>
            <Row className='my-2'>
                <Col className='d-flex justify-content-start align-items-center '>
                    <FontAwesomeIcon icon={'code'} size='lg' color='#292929' className='fa-sharp fa-solid' style={{marginRight: '0.75rem', fontWeight: 'bolder'}}/>
                    <Text className='library-modal-body-title'>{title}</Text>
                </Col>
            </Row>

            <Row className='my-3'>
                {/* TODO: Refactor Rows/Cols so the Table can take up 100% of available height */}
                <Col style={{ height: 550 }}>
                    <Table<R>
                        rowData={rowData}
                        columnDefs={columnDefs}
                        showSearchBar={true}
                        rowSelection={{ mode: 'singleRow' }}
                        onRowSelected={onRowSelected} />
                </Col>
            </Row>
        </Col>
    );
};
