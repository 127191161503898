import React, { FC, useEffect, useState, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { EfcCallToActionInfo, Page, Tab } from '@efilecabinet/efc-atlantis-components';
import { TranslationFiles, useSafeTranslation, MetadataTKeys } from '../../../../hooks/useSafeTranslation';
import { useResizableColUtilities } from '../../../../hooks/useResizableColUtilities';
import { useRouteValidator } from '../../../../hooks/useRouteValidator';
import { useRouting } from '../../../_routing';
import { MetadataRoutePath, RoutePath } from '../../../_routing/routingTypes';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { useMetadataFieldUtilities } from '../../../../hooks/metadata/useMetadataFieldUtilities';
import { useMetadataGroupUtilities } from '../../../../hooks/metadata/useMetadataGroupUtilities';
import { MDManagementSideSheet } from '../_sidesheet/MdManagementSideSheet/MdManagementSideSheet';
import { MDFieldModal } from '../../_fields/_modal/MdFieldModal/MdFieldModal';
import { MDGroupModal } from '../../_groups/_modal/MdGroupModal/MdGroupModal';
import { MdFieldsManagementTable } from '../../_fields/MdFieldsManagementTable/MdFieldsManagementTable';
import { MdGroupsManagementTable } from '../../_groups/MdGroupsManagementTable/MdGroupsManagementTable';
import { MDLibraryFieldsModal } from '../../_library/_modal/MdLibraryFieldsModal/MdLibraryFieldsModal';
import { MDLibraryGroupsModal } from '../../_library/_modal/MdLibraryGroupsModal/MdLibraryGroupsModal';
import { MetadataFieldDecorDto } from '../../../../api/mdFields/mdFieldApiTypes';
import { MetadataGroupDecorDto } from '../../../../api/mdGroups/mdGroupsApiTypes';

const FieldsTabRoutes = new Set<MetadataRoutePath>([
    RoutePath.MetadataIndex,
    RoutePath.MDFields,
]);

const GroupsTabRoutes = new Set<MetadataRoutePath>([
    RoutePath.MDGroups,
]);

export const MetadataManagementPage: FC = () => {

    const { openModal } = useLayerContext();
    const { createEmptyMdField } = useMetadataFieldUtilities();
    const { createEmptyMdGroup } = useMetadataGroupUtilities();
    const { MIN_COLLAPSED_WIDTH, getSideSheetWidth, getRowSubClasses } = useResizableColUtilities();
    const { routeToMDFields, routeToMDGroups } = useRouting();
    const { canGoToRoute } = useRouteValidator();
    const { t } = useSafeTranslation(TranslationFiles.Metadata);

    const [refreshFieldsSentinalKey, setRefreshFieldsSentinalKey] = useState(0); // playing with this pattern, basically this is updated every time a field is changed or added so that the children can just listen for changes
    const [refreshGroupsSentinalKey, setRefreshGroupsSentinalKey] = useState(0); // playing with this pattern, basically this is updated every time a group is changed or added so that the children can just listen for changes
    const [selectedField, setSelectedField] = useState<MetadataFieldDecorDto>(createEmptyMdField());
    const [selectedGroup, setSelectedGroup] = useState<MetadataGroupDecorDto>(createEmptyMdGroup());
    const [isLibraryFieldsModalShowing, setIsLibraryFieldsModalShowing] = useState<boolean>(false);
    const [isLibraryGroupsModalShowing, setIsLibraryGroupsModalShowing] = useState<boolean>(false);

    const currentRoute = `${location.pathname}` as MetadataRoutePath;

    function openAddLibraryFieldsModal() {
        setIsLibraryFieldsModalShowing(true);
    }

    function openAddLibraryGroupsModal() {
        setIsLibraryGroupsModalShowing(true);
    }
    
    function openAddFieldModal() {
        openModal(() => <MDFieldModal onSubmitField={() => setRefreshFieldsSentinalKey(prevState => prevState + 1)} /> );
    }

    function openAddGroupModal() {
        openModal(() => <MDGroupModal onSubmitGroup={() => setRefreshGroupsSentinalKey(prevState => prevState + 1)} /> );
    }

    const ctas: EfcCallToActionInfo[] = useMemo(() => {
        if (FieldsTabRoutes.has(currentRoute)) {
            return [
                {
                    text: t(MetadataTKeys.AddFromLibrary),
                    onClick: openAddLibraryFieldsModal,
                    dataId: 'btnAddFieldFromLibrary',
                },
                {
                    text: t(MetadataTKeys.CreateField),
                    onClick: openAddFieldModal,
                    dataId: 'btnCreateMDField',
                },
            ];
        } else if (GroupsTabRoutes.has(currentRoute)) {
            return [
                {
                    text: t(MetadataTKeys.AddFromLibrary),
                    onClick: openAddLibraryGroupsModal,
                    dataId: 'btnAddGroupFromLibrary',
                },
                {
                    text: t(MetadataTKeys.CreateGroup),
                    onClick: openAddGroupModal,
                    dataId: 'btnCreateMDGroup',
                },
            ];
        } else {
            return [];
        }
    }, [currentRoute]);

    useEffect(() => {
        if (currentRoute == RoutePath.MetadataIndex) {
            routeToMDFields();
        }
    }, [location.pathname]);

    return (
        <>
            <Row className={`metadataRow h-100 pt-3 ${getSideSheetWidth() > MIN_COLLAPSED_WIDTH ? '' : 'w-100'}`} >
                <Col className={`${getRowSubClasses('metadata')}`}>
                    <Page
                        title={t(MetadataTKeys.MetadataTitle)}
                        icon={{ icon: 'code' }}
                        className='metadata-page'
                        callsToAction={ctas}
                        useDropdownCallToAction={true}
                        dropdownText={(FieldsTabRoutes.has(currentRoute) ? t(MetadataTKeys.AddNewField) : t(MetadataTKeys.AddNewGroup))} >
                        {canGoToRoute(RoutePath.MDFields) &&
                            <Tab active={FieldsTabRoutes.has(currentRoute)} onClick={() => routeToMDFields()}>
                                {t(MetadataTKeys.FieldsTab)}
                            </Tab>
                        }
                        {canGoToRoute(RoutePath.MDGroups) &&
                            <Tab active={GroupsTabRoutes.has(currentRoute)} onClick={() => routeToMDGroups()}>
                                {t(MetadataTKeys.GroupsTab)}
                            </Tab>
                        }

                        <Tab.Content activeTab={currentRoute}>
                            <Tab.Pane tabId={RoutePath.MDFields}>
                                <MdFieldsManagementTable
                                    selectedFieldState={[selectedField, setSelectedField]}
                                    active={FieldsTabRoutes.has(currentRoute)}
                                    refreshFieldsSentinalKeyState={[refreshFieldsSentinalKey, setRefreshFieldsSentinalKey]} />
                            </Tab.Pane>
                            <Tab.Pane tabId={RoutePath.MDGroups}>
                                <MdGroupsManagementTable
                                    selectedGroupState={[selectedGroup, setSelectedGroup]}
                                    active={GroupsTabRoutes.has(currentRoute)}
                                    refreshGroupsSentinalKeyState={[refreshGroupsSentinalKey, setRefreshGroupsSentinalKey]} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Page>
                </Col>

                <MDManagementSideSheet
                    currentRoute={currentRoute}
                    selectedMDField={selectedField}
                    selectedMDGroup={selectedGroup}
                    refreshMDFieldsSentinalKeyState={[refreshFieldsSentinalKey, setRefreshFieldsSentinalKey]}
                    refreshMDGroupsSentinalKeyState={[refreshGroupsSentinalKey, setRefreshGroupsSentinalKey]} />
            </Row>
            
            {isLibraryFieldsModalShowing &&
                <MDLibraryFieldsModal isShowingState={[isLibraryFieldsModalShowing, setIsLibraryFieldsModalShowing]} />
            }

            {isLibraryGroupsModalShowing &&
                <MDLibraryGroupsModal isShowingState={[isLibraryGroupsModalShowing, setIsLibraryGroupsModalShowing]} />
            }
        </>
    );
};
