import React, { FC, useEffect } from 'react';

export interface MDModalHeaderProps {
    title: string;
    handleClose?: () => void;
}

export const MDLibraryHeader: FC<MDModalHeaderProps> = ({title, handleClose}) => {

    return (
        <div className='modal-header w-100 p-3'>
            <div className='d-flex flex-wrap align-items-center justify-content-start w-75'>
                <div className='d-flex align-items-center'>
                    <h1 className='library-modal-header-title'>{title}</h1>
                </div>
            </div>

            {!!handleClose &&
                <button type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose} />
            }
        </div>
    );
};
