export interface MDGetFieldsRequest {
    accountId: string;
    mdFieldIds?: string[];
}

export interface MDCheckFieldNameRequest {
    accountId: string;
    name: string;
}

export interface MetadataFieldDto {
    id: string;
    name: string;
    accountId: string;
    createdByUserId?: string;
    type: MDFieldTypes;
    isArchived: boolean;

    textProps?: TextMetadataFieldDto;
    numberProps?: NumberMetadataFieldDto;
    addressProps?: AddressMetadataFieldDto;
    dateProps?: DateMetadataFieldDto;
    checkboxProps?: CheckboxMetadataFieldDto;
    dropdownListProps?: ListMetadataFieldDto;
}

export interface MetadataFieldDecorDto extends MetadataFieldDto {
    createdByDisplayName?: string;
}

export enum MDFieldTypes {
    Text = 0,
    Email,
    Address,
    Date,
    PhoneNumber,
    Checkbox, //True/False
    Number,
    DropdownList,
}

export interface TextMetadataFieldDto {
    textFormat: TextFormatTypes;
    allowedCharacters: AllowedCharacterTypes;
    minChars?: number;
    maxChars?: number;
    prefix?: string;
    defaultValue?: string;
    sensitiveDataFormat?: SensitiveDataFormat;
}

export enum TextFormatTypes {
    SingleLine = 0,
    MultiLine,
}

export enum AllowedCharacterTypes {
    AllCharacters = 0,
}

export interface SensitiveDataFormat {
    mode: SensitiveDataMode;
    beginningCharCount?: number;
    endingCharCount?: number;
}

export enum SensitiveDataMode {
    Hide = 0,
    Show = 1,
}

export interface NumberMetadataFieldDto {
    numberFormat: NumberFormatTypes;
    minValue?: number;
    maxValue?: number;
    sensitiveDataFormat?: SensitiveDataFormat;
}

export enum NumberFormatTypes {
    Number = 0,
    Percentage,
    Decimal,
    Currency,
}

export interface AddressMetadataFieldDto {
    includeAddressOne: boolean;
    includeAddressTwo: boolean;
    includeCity: boolean;
    includeState: boolean;
    includePostal: boolean;
    includeCountry: boolean;
}

export interface DateMetadataFieldDto {
    dateFormat: DateFormatTypes;
}

export enum DateFormatTypes {
    mmddyyyy = 0,
    ddmmyyyy,
    yyyymmdd,
    monthddyyyy,
    ddmonthyyyy,
}

export interface CheckboxMetadataFieldDto {
    defaultValue: boolean;
}

export interface ListMetadataFieldDto {
    dropdownListFormat: DropdownListFormatTypes;
    allowNewOptions: boolean;
    options?: MDFieldDropdownListOption[];
    defaultOption?: string;
}

export interface MDFieldDropdownListOption {
    id: string;
    value: string;
}

export enum DropdownListFormatTypes {
    SingleSelect = 0,
    MultiSelect
}

export enum MdFieldOrGroupEnum {
    Field = 'Field',
    Group = 'Group'
}