import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export const SmartDataSkeletonLoader = () => {
    return (
        <>
            <div>
                <SkeletonTheme enableAnimation height='24px' width='80%'>
                    <Skeleton count={6} className='mb-3' />
                </SkeletonTheme>
            </div>
            <div>
                <div>
                    <SkeletonTheme enableAnimation height='38px' width='100%'>
                        <Skeleton count={1} />
                    </SkeletonTheme>
                </div>
                <div>
                    <SkeletonTheme enableAnimation height='16px' width='16px'>
                        <Skeleton count={1} />
                    </SkeletonTheme>
                </div>
            </div>
        </>
    );
};