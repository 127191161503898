import { MDFieldTypes, MetadataFieldDecorDto, MetadataFieldDto } from '../../api/mdFields/mdFieldApiTypes';
import { useMDFieldApi } from '../../api/mdFields/useMDFieldApi';
import { useMDLibraryFieldsApi } from '../../api/mdLibrary/useMDLibraryFieldsApi';

export const useMetadataFieldUtilities = () => {
    const BATCH_SIZE = 100;

    const { queryMdFieldsAsync } = useMDFieldApi();
    const { queryMdLibraryFieldsAsync } = useMDLibraryFieldsApi();

    function createEmptyMdField() {
        return { id: '', name: '', accountId: '', type: MDFieldTypes.Text, isArchived: false };
    }

    async function loadAllMdAccountFieldsAsync(accountId: number) {
        const currentFields: MetadataFieldDecorDto[] = [];
        let allFieldsLoaded = false;

        while (!allFieldsLoaded) {
            await queryMdFieldsAsync(accountId, currentFields.length, BATCH_SIZE)
                .then((fields) => {
                    if (!!fields) {
                        if (fields.length < BATCH_SIZE) {
                            allFieldsLoaded = true;
                        }
                        currentFields.push(...fields);
                    } else {
                        allFieldsLoaded = true;
                    }
                });
        }

        return currentFields;
    }

    async function loadAllMdLibraryFieldsAsync() {
        const currentFields: MetadataFieldDto[] = [];
        let allFieldsLoaded = false;

        while (!allFieldsLoaded) {
            await queryMdLibraryFieldsAsync(currentFields.length, BATCH_SIZE)
                .then((fields) => {
                    if (!!fields) {
                        if (fields.length < BATCH_SIZE) {
                            allFieldsLoaded = true;
                        }
                        currentFields.push(...fields);
                    } else {
                        allFieldsLoaded = true;
                    }
                });
        }

        return currentFields;
    }


    return {
        createEmptyMdField,
        loadAllMdAccountFieldsAsync,
        loadAllMdLibraryFieldsAsync,
    };
};