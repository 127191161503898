import React, { useEffect, useState } from 'react';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { MDSideSheetInformationTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { MetadataGroupDecorDto } from '../../../../../api/mdGroups/mdGroupsApiTypes';
import { useAuthContext } from '../../../../../app/_context/AuthContext';
import { useMetadataFieldUtilities } from '../../../../../hooks/metadata/useMetadataFieldUtilities';
import { SidesheetPage } from '../../../../../components/SideSheet/SidesheetPage';
import { GroupDetailsNestedCollapse } from '../../../_groups/_groupDetails/GroupDetailsNestedCollapse';
import { MDGroupDetailsFieldProperty } from '../MdGroupDetailsFieldProperty/MdGroupDetailsFieldProperty';
import { MdGroupDetailsSkeletonLoader } from '../MdGroupDetailsSkeletonLoader/MdGroupDetailsSkeletonLoader';

interface MDGroupSideSheetContentProps {
    mdGroup: MetadataGroupDecorDto;
    groupAdded?: boolean;
    // refreshMDGroupsSentinalKeyState: [number, Dispatch<SetStateAction<number>>];
    onClose?: () => void;
}

export interface MDGroupDetailField {
    id: string;
    name: string;
    type: number;
    isInAccount?: boolean;
}

export const MDGroupSideSheetContent = ({ mdGroup, groupAdded, onClose }: MDGroupSideSheetContentProps) => {
    // const [refreshMDGroupsSentinalKey, setRefreshMDGroupsSentinalKey] = refreshMDGroupsSentinalKeyState;

    const { t } = useSafeTranslation(TranslationFiles.MDSideSheetInformation);
    const { loadAllMdAccountFieldsAsync } = useMetadataFieldUtilities();
    const { authUser } = useAuthContext();

    const [updatedFields, setUpdatedFields] = useState<MDGroupDetailField[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (mdGroup) {
            setIsLoading(true);
            const fetchMetadataFields = async () => {
                await loadAllMdAccountFieldsAsync(authUser?.accountID as number).then((accountFields) => {
                    const fieldsWithAccountInfo = mdGroup.metadataFields.map((field) => ({
                        id: field.id,
                        name: field.allProperties.name,
                        type: field.allProperties.type,
                        isInAccount: accountFields?.some((accountField) => accountField.name === field.allProperties.name),
                    }));
                    setUpdatedFields(fieldsWithAccountInfo);
                    setIsLoading(false);
                });
            };

            fetchMetadataFields();
        }
    }, [mdGroup]);

    return !mdGroup || updatedFields.length == 0 ? (
        <div className='d-flex align-items-center justify-content-center h-50'>
            <Text>{t(MDSideSheetInformationTKeys.SelectGroup)}</Text>
        </div>
    ) : (
        <SidesheetPage title={mdGroup.name} onClose={onClose}>
            <div className='my-3 md-sidesheet-title-divider'></div>
            {isLoading ? (
                <MdGroupDetailsSkeletonLoader />
            ) : (
                <GroupDetailsNestedCollapse openByDefault={true}>
                    {updatedFields.map((field) => (
                        <MDGroupDetailsFieldProperty key={field.id} fieldName={field.name} fieldType={field.type} isInAccount={field.isInAccount} isAdded={groupAdded} />
                    ))}
                </GroupDetailsNestedCollapse>
            )}
        </SidesheetPage>
    );
};
