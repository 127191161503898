import React from 'react';
import { AdditionalFeedback, ExtractedItem, UserFeedback } from '../../../../../api/smartData/smartDataApiTypes';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { useColors } from '../../../../../hooks/useColors';
import { SmartDataItemFeedback, FeedbackEnum } from '../SmartDataItemFeedback/SmartDataItemFeedback';

interface ExtractedValueProps {
    extractedItem: ExtractedItem;
    userFeedback: UserFeedback | undefined;
    onFeedbackChange: (id: string, newFeedback: number, isFullOutputFeedback: boolean, isOpenAiResponseFeedback: boolean, additionalFeedback?: AdditionalFeedback) => void;
}

export const ExtractedValue = ({ extractedItem, userFeedback, onFeedbackChange }: ExtractedValueProps) => {
    const { atlantisPrimary, atlantisMuted } = useColors();

    const onFeedbackClick = (iconType: FeedbackEnum, additionalFeedback?: AdditionalFeedback) => {
        if (!!userFeedback && userFeedback.feedback === iconType) {
            return;
        }

        onFeedbackChange(extractedItem.id, iconType, false, false, additionalFeedback);
    };

    return (
        <div className='mb-3 mx-2'>
            <Text color={atlantisMuted}>{extractedItem.key}</Text>
            <div className='ms-3 d-flex justify-content-between align-items-center mt-1'>
                <Text color={atlantisPrimary} className='pe-2'>{extractedItem.value}</Text>
                <SmartDataItemFeedback extractedValueId={extractedItem.id} canOpenModal={true} isFullTextFeedback={false} feedbackValue={userFeedback?.feedback} onFeedbackClick={onFeedbackClick} />
            </div>
        </div>
    );
};
