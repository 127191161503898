import { RoutePath } from '../../features/_routing/routingTypes';
import { useDocumentsRouting } from './_hooks/useDocumentsRouting';
import { useDocRequestsRouting } from './_hooks/useDocRequestsRouting';
import { useESignatureRouting } from './_hooks/useESignatureRouting';
import { useWorkflowRouting } from './_hooks/useWorkflowRouting';
import { useUsersRouting } from './_hooks/useUsersRouting';
import { useCheckedOutRouting } from './_hooks/useCheckedOutRouting';
import { useFolderTemplatesRouting } from './_hooks/useFolderTemplatesRouting';
import { useUserSettingsRouting } from './_hooks/useUserSettingsRouting';
import { useMetadataRouting } from './_hooks/useMetadataRouting';
import { useProfilesRouting } from './_hooks/useProfilesRouting';
import { useAccountSettingsRouting } from './_hooks/useAccountSettingsRouting';
import { useSecurityPoliciesRouting } from './_hooks/useSecurityPoliciesRouting';
import { useSalesforceRouting } from './_hooks/useSalesforceRouting';
import { useUtilitiesRouting } from './_hooks/useUtilitiesRouting';
import { useToDoListRouting } from './_hooks/useToDoListRouting';
import { useSearchRouting } from './_hooks/useSearchRouting';
import { useHomeRouting } from './_hooks/useHomeRouting';
import { useReportsRouting } from './_hooks/useReportsRouting';
import { useRecycleBinRouting } from './_hooks/useRecycleBinRouting';
import { useFileQueueRouting } from './_hooks/useFileQueueRouting';
import { useFormsRouting } from './_hooks/useFormsRouting';
import { useGovernanceRouting } from './_hooks/useGovernanceRouting';
import { useAuditLogsRouting } from './_hooks/useAuditLogsRouting';
import { useRecentsRouting } from './_hooks/useRecentsRouting';
import { useWorkYouCareRouting } from './_hooks/useWorkYouCareRouting';
import { useSystemLogsRouting } from './_hooks/useSystemLogsRouting';
import { useSmartDataRouting } from './_hooks/useSmartDataRouting';

export const useRouting = () => {

    function doNotRedirectToRoute(route: string) {
        const doNotGoToRoutes = [RoutePath.Login, RoutePath.PageNotFound];
        return doNotGoToRoutes.some(i => !!route?.startsWith(i));
    }

    return {
        doNotRedirectToRoute,
        ...useHomeRouting(),
        ...useDocumentsRouting(),
        ...useDocRequestsRouting(),
        ...useESignatureRouting(),
        ...useWorkflowRouting(),
        ...useReportsRouting(),
        ...useCheckedOutRouting(),
        ...useRecycleBinRouting(),
        ...useFileQueueRouting(),
        ...useFolderTemplatesRouting(),
        ...useUserSettingsRouting(),
        ...useMetadataRouting(),
        ...useProfilesRouting(),
        ...useFormsRouting(),
        ...useGovernanceRouting(),
        ...useUsersRouting(),
        ...useAuditLogsRouting(),
        ...useAccountSettingsRouting(),
        ...useSecurityPoliciesRouting(),
        ...useSalesforceRouting(),
        ...useUtilitiesRouting(),
        ...useSearchRouting(),
        ...useRecentsRouting(),
        ...useWorkYouCareRouting(),
        ...useToDoListRouting(),
        ...useSystemLogsRouting(),
        ...useSmartDataRouting(),
    };
};
