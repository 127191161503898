import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { useSettingsAndFeatures } from '../../app/_context/SettingFeaturesContext/SettingsFeaturesContext';
import { MetadataGroupDto, MDGetGroupRequest, MetadataGroupDecorDto, MDGetGroupByNameRequest } from './mdGroupsApiTypes';

export const useMDGroupsApi = () => {

    const { settings } = useSettingsAndFeatures();

    const getMdGroupAsync = async (id: string): Promise<MetadataGroupDecorDto> => {
        const resp: AxiosResponse = await Api.get(`${settings?.utopiaUrl}/api/Metadata/Group/${id}`);
        return resp.data as MetadataGroupDecorDto;
    };

    const getMdGroupByNameAsync = async (request: MDGetGroupByNameRequest): Promise<MetadataGroupDecorDto> => {
        const resp: AxiosResponse = await Api.post(`${settings?.utopiaUrl}/api/Metadata/Group/GetByName`, request);
        return resp.data as MetadataGroupDecorDto;
    };

    const queryMdGroupsAsync = async (accountId: number, start: number, count: number): Promise<MetadataGroupDecorDto[]> => {
        const resp: AxiosResponse = await Api.get(`${settings?.utopiaUrl}/api/Metadata/Group/Account/${accountId}/Start/${start}/Count/${count}`);
        return resp.data as MetadataGroupDecorDto[];
    };

    const queryMdGroupsByIdsAsync = async (request: MDGetGroupRequest): Promise<MetadataGroupDecorDto[]> => {
        const resp: AxiosResponse = await Api.post(`${settings?.utopiaUrl}/api/Metadata/Group/Query`, request);
        return resp.data as MetadataGroupDecorDto[];
    };

    const createMdGroupAsync = async (mdGroup: MetadataGroupDto): Promise<MetadataGroupDecorDto> => {
        const resp: AxiosResponse = await Api.post(`${settings?.utopiaUrl}/api/Metadata/Group`, mdGroup);
        return resp.data as MetadataGroupDecorDto;
    };

    const updateMdGroupAsync = async (mdGroup: MetadataGroupDto): Promise<MetadataGroupDecorDto> => {
        const resp: AxiosResponse = await Api.put(`${settings?.utopiaUrl}/api/Metadata/Group`, mdGroup);
        return resp.data as MetadataGroupDecorDto;
    };

    const deleteMdGroupAsync = async (mdGroupId: string): Promise<void> => {
        await Api.delete(`${settings?.utopiaUrl}/api/Metadata/Group/${mdGroupId}`);
    };

    return {
        getMdGroupAsync,
        getMdGroupByNameAsync,
        queryMdGroupsAsync,
        queryMdGroupsByIdsAsync,
        createMdGroupAsync,
        updateMdGroupAsync,
        deleteMdGroupAsync,
    };
};