import React, { Dispatch, SetStateAction } from 'react';
import { AtlantisTaskDTO } from '../../../../api/tasks/taskApiTypes';
import { AddTaskModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { Modal } from '@efilecabinet/nautilus-ui';
import { useAddTaskModalForm } from './UseAddTaskModalForm';
import { AddTaskModalBody } from './AddTaskModalBody';

export interface AddTaskModalProps {
    isShowingState: [boolean, Dispatch<SetStateAction<boolean>>];
    submitTask: (text: AtlantisTaskDTO) => Promise<void>;
}

export const AddTaskModal = (addTaskModalProps: AddTaskModalProps) => {

    const addTaskModalFormData = useAddTaskModalForm(addTaskModalProps);
    const { modalElementId, footerButtons } = addTaskModalFormData;

    const { t } = useSafeTranslation(TranslationFiles.AddTaskModal);

    return (
        <Modal
            elementId={modalElementId}
            dataId={modalElementId}
            isShowingState={addTaskModalProps.isShowingState}
            header={{ title: t(AddTaskModalTKeys.Title) }}
            body={{
                children: <AddTaskModalBody {...addTaskModalFormData} />
            }}
            footer={footerButtons}/>
    );
};