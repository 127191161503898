import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useLocation, useParams } from 'react-router';
import { Card, Page, EfcCallToActionInfo, Modal, Button } from '@efilecabinet/efc-atlantis-components';
import { useUtopiaIFrame } from '../../../hooks/useUtopiaIFrame';
import { useDocumentsUtilities } from '../../../hooks/documents/useDocumentsUtilities';
import { useSyncUtopiaDocumentsIFrameToAtlantisRoute } from '../../../hooks/useSyncUtopiaDocumentsIFrameToAtlantisRoute';
import { useUtopiaIFrameRoutes } from '../../../hooks/useUtopiaIFrameRoutes';
import { DocumentsTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';
import { RoutePath } from '../../_routing/routingTypes';
import { IDocumentURLDetails, IIFrameURLUpdateDetails } from '../../../hooks/documents/documentsTypes';
import { IFrame } from '../../../components/IFrame/IFrame';
import { FullScreenPreviewerModal } from '../../../components/Previewer/PreviewerModals/FullScreenPreviewerModal/FullScreenPreviewerModal';
import { useResizableColUtilities } from '../../../hooks/useResizableColUtilities';
import { UploadLinkModal } from '../../_uploadLinks/_uploadLinkModal/UploadLinkModal/UploadLinkModal';
import { useLayerContext } from '../../../app/_context/LayerContext/LayerContext';
import { useFileVersionApi } from '../../../api/fileVersions/useFileVersionApi';
import { useSmartDataRouting } from '../../_routing/_hooks/useSmartDataRouting';
import { useAuthContext } from '../../../app/_context/AuthContext';
import { useNodeDetailsContext } from '../../../components/NodeSideSheet/_context/NodeDetailsContext/NodeDetailsContext';
import { useFileUtilities } from '../../../hooks/useFileUtilities';
import './DocumentsPage.css';

export const DocumentsPage = () => {
    const { collectionId, containerNodeId, nodeIds, selectedDetail } = useParams();

    const { getFileVersions } = useFileVersionApi();
    const location = useLocation();
    const iframe = useUtopiaIFrame();
    const { getIFrameUrl } = useDocumentsUtilities();
    const { t } = useSafeTranslation(TranslationFiles.DocumentLayer);
    const { IFrameDocumentRoutes } = useUtopiaIFrameRoutes();
    const { getShowOverlay } = useResizableColUtilities();
    const { openModal } = useLayerContext();
    const { routeToSmartDataExtraction } = useSmartDataRouting();
    const { hasAuthUser } = useAuthContext();
    const { fetchNodeSmartDataForActiveNode, nodeSmartData, fetchNode, activeNode, hasSmartDataFeature } = useNodeDetailsContext();
    const { nodeIsContainer } = useFileUtilities();

    const [displayInvalidNodeMessage, setDisplayInvalidNodeMessage] = useState(false);
    const [invalidNodeMessage, setInvalidNodeMessage] = useState<string>(t(DocumentsTKeys.InvalidNodeMessage));
    const [activeNodeIds, setActiveNodeIds] = useState<string[]>(nodeIds?.split(',') ?? []);
    const [lastFetchedNodeId, setLastFetchedNodeId] = useState<string | null>(null);
    const [showSDEBtn, setShowSDEBtn] = useState(false);

    const hideInvalidNodeMessage = () => setDisplayInvalidNodeMessage(false);

    // when in the documents component, the first click of of the back buttons reverts the Atlantis url, the second click reverts the iframe url
    // we are monitoring back button clicks so we can programmatically click it a second time so the user doesn't have to
    const onBackButtonClick = () => {
        if (window.history.length > 1) {
            window.history.go(-1);
        }
    };

    const handleMessage = async (e: MessageEvent) => {
        if (e.data?.type === 'SHOW_CREATE-UPLOAD-LINK_MODAL' || e.data?.type === 'SHOW_VIEW-UPLOAD-LINK_MODAL') {
            openModal((closeModalFn) => <UploadLinkModal destroyModal={closeModalFn} node={e.data.node} uploadLink={e.data.uploadLink} type={e.data.type} />);
        }

        if (e.data?.type === 'SHOW_FULLSCREEN_MODAL') {
            const node = e.data.node;
            if (!!node?.id) {
                const fileVersionsData = await getFileVersions(node.id);
                const activeFileVersion = fileVersionsData.find((v) => v.isActive);
                openModal((closeModalFn) => <FullScreenPreviewerModal node={node} fileVersionId={activeFileVersion?.id} onClose={closeModalFn} />);
            }
        }
    };

    const confirmationCTAs: EfcCallToActionInfo[] = [{ text: t(DocumentsTKeys.CloseCta), color: 'primary', emphasis: 'med', onClick: () => hideInvalidNodeMessage() }];

    // Sets up a listener for navigation within the documents page to make sure the Atlantis url is keeping up
    useSyncUtopiaDocumentsIFrameToAtlantisRoute();

    useEffect(() => {
        window.addEventListener('popstate', onBackButtonClick);
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('popstate', onBackButtonClick);
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        if (!iframe.url || !(location.state as any)?.isIFrameSrcChange) {
            const documentURLDetails: IDocumentURLDetails = {
                collectionId,
                containerNodeId,
                nodeIds,
                selectedDetail,
            };
            getIFrameUrl(documentURLDetails).then((iFrameURLUpdateDetails: IIFrameURLUpdateDetails) => {
                if (!!iFrameURLUpdateDetails.invalidNodesMessage) {
                    setInvalidNodeMessage(iFrameURLUpdateDetails.invalidNodesMessage);
                    setDisplayInvalidNodeMessage(true);
                }
                iframe.setUrl(iFrameURLUpdateDetails.updatedIFrameURL ?? IFrameDocumentRoutes.get(RoutePath.Documents) ?? '');
            });
        }
    }, [location.pathname]);

    useEffect(() => {
        setActiveNodeIds(nodeIds?.split(',') ?? []);
    }, [nodeIds]);

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //  TEMP SMART DATA ALPHA CODE
    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const handleSmartDataClick = () => {
        if (!activeNodeIds) return;
        routeToSmartDataExtraction(activeNodeIds[0]);
    };

    useEffect(() => {
        const fetchNodeData = async () => {
            if (activeNodeIds.length === 1 && activeNodeIds[0] !== lastFetchedNodeId) {
                const nodeId = activeNodeIds[0];
                try {
                    await fetchNode(nodeId);
                    setLastFetchedNodeId(nodeId);
                } catch (error) {
                    console.error('Error fetching node:', error);
                }
            }
        };

        fetchNodeData();
    }, [activeNodeIds, lastFetchedNodeId]);

    useEffect(() => {
        if (!!hasAuthUser && !!activeNode && !nodeIsContainer(activeNode.systemType) && (!nodeSmartData || nodeSmartData.nodeId !== lastFetchedNodeId)) {
            const displayValidationMessage = false;
            fetchNodeSmartDataForActiveNode(displayValidationMessage);
        }
    }, [activeNode, lastFetchedNodeId, hasAuthUser]);

    useEffect(() => {
        setShowSDEBtn(hasSmartDataFeature && !!activeNode && !nodeIsContainer(activeNode.systemType));
    }, [hasSmartDataFeature, activeNode])
    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //  TEMP SMART DATA ALPHA CODE END
    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

    return (
        <Page>
            <Row className='docRow pt-3'>
                <Col className='h-100 pe-0 documentsIframeContainer'>
                    {showSDEBtn && (
                        <Button size='sm' className='sde-btn' icon={{ icon: 'sparkles', beat: true }} onClick={handleSmartDataClick}>
                            Smart Data Alpha
                        </Button>
                    )}
                    <Card fluid className='h-100 utopia-iframe-border-overlay'>
                        {!!getShowOverlay() && <div className='overlay'></div>}
                        {/* Use the iframe until we make this page native */}
                        <IFrame name='utopiaIframe' className='h-100 m-0' src={iframe.url} dataId='documentsIframe' />
                    </Card>
                </Col>
            </Row>
            <Modal toggle={hideInvalidNodeMessage} title={t(DocumentsTKeys.InvalidNodeTitle)} isOpen={displayInvalidNodeMessage} ctas={confirmationCTAs}>
                <Modal.Body>{`${invalidNodeMessage}`}</Modal.Body>
            </Modal>
        </Page>
    );
};
