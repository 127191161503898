import React from 'react';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { AdditionalFeedback, UserFeedback } from '../../../../../api/smartData/smartDataApiTypes';
import { SmartDataItemFeedback, FeedbackEnum } from '../SmartDataItemFeedback/SmartDataItemFeedback';
import { NestedCollapse } from '../../../../NestedCollapse';
import './FullOutput.css';

interface FullOutputProps {
    fullOutput: string | undefined;
    userFeedback: UserFeedback | undefined;
    onFeedbackChange: (id: string, newFeedback: number, isFullOutputFeedback: boolean, isOpenAiResponseFeedback: boolean, additionalFeedback?: AdditionalFeedback) => void;
    label: string;
    isAIOutput: boolean;
    isTextOutput: boolean;
}

export const FullOutput = ({ fullOutput, userFeedback, onFeedbackChange, label, isAIOutput, isTextOutput }: FullOutputProps) => {
    const REVVER_FULL_TEXT = 'REVVER_FULL_TEXT';

    const onFeedbackClick = (iconType: FeedbackEnum, additionalFeedback?: AdditionalFeedback) => {
        if (!!userFeedback && userFeedback.feedback === iconType) {
            return;
        }

        onFeedbackChange('', iconType, isTextOutput, isAIOutput, additionalFeedback);
    };

    return (
        <div className='mb-2 output-container'>
            <NestedCollapse title={label} color='default'>
                {isAIOutput ? (
                    <pre>
                        <code>{fullOutput}</code>
                    </pre>
                ) : (
                    <Text className='ms-2'>{fullOutput}</Text>
                )}
                <div className='d-flex justify-content-center align-items-center mt-2'>
                    <SmartDataItemFeedback extractedValueId={REVVER_FULL_TEXT} canOpenModal={true} isFullTextFeedback={true} feedbackValue={userFeedback?.feedback} onFeedbackClick={onFeedbackClick} />
                </div>
            </NestedCollapse>
        </div>
    );
};
