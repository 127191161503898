import React, { useEffect, useState } from 'react';
import { SmartDataTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { AdditionalFeedback } from '../../../../../api/smartData/smartDataApiTypes';
import { useLayerContext } from '../../../../../app/_context/LayerContext/LayerContext';
import { ExtractedValueModal } from '../ExtractedValue/ExtractedValueModal/ExtractedValueModal';
import { Tooltip } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SmartDataItemFeedback.css';

export enum FeedbackEnum {
    Inaccurate = 0,
    PartiallyAccurate = 1,
    Accurate = 2
}

interface SmartDataItemFeedbackProps {
    extractedValueId: string;
    canOpenModal: boolean;
    isFullTextFeedback: boolean;
    feedbackValue: FeedbackEnum | undefined;
    onFeedbackClick: (iconType: FeedbackEnum, additionalFeedback?: AdditionalFeedback) => void;
}

export const SmartDataItemFeedback = ({ extractedValueId, canOpenModal, isFullTextFeedback, feedbackValue, onFeedbackClick }: SmartDataItemFeedbackProps) => {
    const { t } = useSafeTranslation(TranslationFiles.SmartData);
    const { openModal } = useLayerContext();

    const [feedback, setFeedback] = useState<FeedbackEnum | undefined>(feedbackValue);

    const [frownIconStyle, setFrownIconStyle] = useState<string>('icon-default');
    const [mehIconStyle, setMehIconStyle] = useState<string>('icon-default');
    const [smileIconStyle, setSmileIconStyle] = useState<string>('icon-default');
    const [isFrownToolTipOpen, setIsFrownToolTipOpen] = useState<boolean>(false);
    const [isMehToolTipOpen, setIsMehToolTipOpen] = useState<boolean>(false);
    const [isSmileToolTipOpen, setIsSmileToolTipOpen] = useState<boolean>(false);

    const updateFeedback = (iconType: FeedbackEnum, additionalFeedback?: AdditionalFeedback) => {
        setFeedback(iconType);
        onFeedbackClick(iconType, additionalFeedback);
    };

    const onClick = (iconType: FeedbackEnum) => {
        if(iconType === feedback) return;

        if (canOpenModal) {
            if (iconType === FeedbackEnum.Accurate) {
                updateFeedback(iconType);
            } else {
                openModal((closeModalFn) => <ExtractedValueModal extractedValueId={extractedValueId} isFullTextFeedback={isFullTextFeedback} feedbackValue={iconType} updateFeedback={updateFeedback} onClose={closeModalFn} />);
            }
        } else {
            updateFeedback(iconType);
        }
    };

    const getIconStyle = (iconType: FeedbackEnum) => {
        if (feedback === undefined) {
            return 'icon-default';
        } else if (feedback === iconType) {
            return 'icon-selected';
        } else {
            return 'icon-not-selected';
        }
    };

    useEffect(() => {
        setFrownIconStyle(getIconStyle(FeedbackEnum.Inaccurate));
        setMehIconStyle(getIconStyle(FeedbackEnum.PartiallyAccurate));
        setSmileIconStyle(getIconStyle(FeedbackEnum.Accurate));
    }, [feedback]);

    useEffect(() => {
        setFeedback(feedbackValue);
    }, [feedbackValue]);

    return (
        <div className='feedback-container'>
            <FontAwesomeIcon icon={['far', 'frown']} className={`feedback-icon ${frownIconStyle}`} id={`feedback-icon-frown-${extractedValueId}-${canOpenModal}`} onClick={() => onClick(FeedbackEnum.Inaccurate)} />
            <Tooltip className='tooltip-purple' target={`feedback-icon-frown-${extractedValueId}-${canOpenModal}`} isOpen={isFrownToolTipOpen} toggle={() => setIsFrownToolTipOpen((prevState) => !prevState)} placement='bottom'>
                {t(SmartDataTKeys.Inaccurate)}
            </Tooltip>

            <FontAwesomeIcon icon={['far', 'meh']} className={`feedback-icon ${mehIconStyle}`} id={`feedback-icon-meh-${extractedValueId}-${canOpenModal}`} onClick={() => onClick(FeedbackEnum.PartiallyAccurate)} />
            <Tooltip className='tooltip-purple' target={`feedback-icon-meh-${extractedValueId}-${canOpenModal}`} isOpen={isMehToolTipOpen} toggle={() => setIsMehToolTipOpen((prevState) => !prevState)} placement='bottom'>
                {t(SmartDataTKeys.PartiallyAccurate)}
            </Tooltip>

            <FontAwesomeIcon icon={['far', 'smile']} className={`feedback-icon ${smileIconStyle}`} id={`feedback-icon-smile-${extractedValueId}-${canOpenModal}`} onClick={() => onClick(FeedbackEnum.Accurate)} />
            <Tooltip className='tooltip-purple' target={`feedback-icon-smile-${extractedValueId}-${canOpenModal}`} isOpen={isSmileToolTipOpen} toggle={() => setIsSmileToolTipOpen((prevState) => !prevState)} placement='bottom'>
                {t(SmartDataTKeys.Accurate)}
            </Tooltip>
        </div>
    );
};