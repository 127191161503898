import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label, Text } from '@efilecabinet/efc-atlantis-components';
import { SidesheetPage } from '../../../SideSheet/SidesheetPage';
import { SmartDataTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { useAuthContext } from '../../../../app/_context/AuthContext/useAuthContext';
import { useNodeDetailsContext } from '../../_context/NodeDetailsContext/NodeDetailsContext';
import { useSmartDataApi } from '../../../../api/smartData/useSmartDataApi';
import { useFileProcessingQueueApi } from '../../../../api/fileProcessingQueue/useFileProcessingQueueApi';
import { useIcons } from '../../../../hooks/useIcons';
import { AdditionalFeedback, SmartDataItem } from '../../../../api/smartData/smartDataApiTypes';
import { SmartDataSkeletonLoader } from './SmartDataSkeletonLoader';
import { ExtractedValue } from './ExtractedValue/ExtractedValue';
import { FullOutput } from './FullOutput/FullOutput';
import { ThemeEnum } from '../../../../hooks/useColors';
import { FeedbackEnum } from './SmartDataItemFeedback/SmartDataItemFeedback';

interface SmartDataProps {
    onClose?: () => void;
}

enum DisplayState {
    NoSmartData,
    NodeInFileQueue,
    HasSmartData,
}

export const SmartData = ({ onClose }: SmartDataProps) => {
    const { t } = useSafeTranslation(TranslationFiles.SmartData);

    const { addOrPatchUserFeedback } = useSmartDataApi();
    const { retryFileQueueItems, getFileProcessingQueue } = useFileProcessingQueueApi();
    const { showToastMessage } = useLayerContext();
    const { authUser, hasAuthUser } = useAuthContext();
    const { activeNode, nodeSmartData, fetchNodeSmartDataForActiveNode, hasSmartDataFeature, isLoadingSmartData } = useNodeDetailsContext();
    const { spinnerIconProps } = useIcons();

    const [nodeIsInFileQueue, setNodeIsInFileQueue] = useState(false);
    const [fetchingSmartData, setFetchingSmartData] = useState(false);
    const [isQueueing, setIsQueueing] = useState(false);
    const [activeSmartDataItem, setActiveSmartDataItem] = useState<SmartDataItem>();
    const [displayState, setDisplayState] = useState<DisplayState>(DisplayState.NoSmartData);

    const getFileQueueItems = async () => {
        if (!authUser) return;

        const fileProcessingQueryParams = {
            accountId: authUser?.accountID,
            start: 0,
            count: 100,
        };
        try {
            const fileQueueItems = await getFileProcessingQueue(fileProcessingQueryParams);
            if (fileQueueItems.length) {
                const nodeIsInQueue = fileQueueItems.some((item) => item.nodeID.toString() === activeNode?.id && item.fileInfoID === activeNode?.fileInfo.id);
                setNodeIsInFileQueue(nodeIsInQueue);
            }
        } catch (error) {
            console.error('Error fetching file queue items:', error);
        }
    };

    const onFeedbackChange = async (id: string, newFeedback: number, isFullOutputFeedback: boolean, isOpenAiResponseFeedback: boolean, additionalFeedback?: AdditionalFeedback) => {
        let userFeedback = activeSmartDataItem?.userFeedbackData.find((f) => f.extractedValueId == id);
        if (!!userFeedback) {
            userFeedback.feedback = newFeedback;
            userFeedback.additionalFeedback = additionalFeedback;
        } else if (!!activeNode && !!authUser && !!activeSmartDataItem) {
            userFeedback = {
                nodeId: activeNode.id,
                promptVersion: activeSmartDataItem?.promptVersion,
                userId: authUser.userID.toString(),
                feedback: newFeedback,
                additionalFeedback: additionalFeedback,
                extractedValueId: id,
                isFullOutputFeedback,
                isOpenAiResponseFeedback,
                updatedOn: new Date().toISOString(),
            };
        }
        if (!!userFeedback) {
            try {
                const result = await addOrPatchUserFeedback(userFeedback);
                if (!!result && newFeedback != FeedbackEnum.Accurate) {
                    showToastMessage({
                        color: ThemeEnum.Success,
                        identifier: 'success-loading-smartData-toast',
                        message: t(SmartDataTKeys.SuccessSendingFeedback),
                        timeout: 2500,
                    });
                }
            } catch (error) {
                showToastMessage({
                    color: ThemeEnum.Danger,
                    identifier: 'error-loading-smartData-toast',
                    message: t(SmartDataTKeys.ErrorSendingFeedback),
                });
            }
        }
    };

    const onRefreshClick = async () => {
        setFetchingSmartData(true);
        await fetchNodeSmartDataForActiveNode();
        setFetchingSmartData(false);
    };

    const handlePromptChange = (promptVersion: string) => {
        const selectedItem = nodeSmartData?.smartDataItems.find((item) => item.promptVersion === promptVersion);
        if (selectedItem) {
            setActiveSmartDataItem(selectedItem);
        }
    };

    const queueItemForSmartDataExtraction = async () => {
        setIsQueueing(true);
        try {
            if (!!activeNode && activeNode.id && !isQueueing) {
                const nodeId = +activeNode.id;
                await retryFileQueueItems([nodeId]);
                await getFileQueueItems();
            }
        } catch (error) {
            console.log('Error queueing item for smart data extraction:', error);
        } finally {
            setIsQueueing(false);
        }
    };

    useEffect(() => {
        if (!!hasAuthUser && (!nodeSmartData || nodeSmartData?.nodeId !== activeNode?.id.toString())) {
            getFileQueueItems();
        }
    }, [nodeSmartData, hasAuthUser]);

    useEffect(() => {
        if (!!hasSmartDataFeature && !!activeNode && !nodeIsInFileQueue && (!nodeSmartData || nodeSmartData?.nodeId !== activeNode.id.toString())) {
            fetchNodeSmartDataForActiveNode();
        }
    }, [nodeIsInFileQueue, hasSmartDataFeature, activeNode, nodeSmartData]);

    useEffect(() => {
        if (!!nodeSmartData && nodeSmartData.nodeId === activeNode?.id.toString()) {
            if (nodeSmartData.smartDataItems.length) {
                setActiveSmartDataItem(nodeSmartData.smartDataItems[0]);
            }
        }
    }, [nodeSmartData]);

    useEffect(() => {
        if (!!nodeSmartData && !!activeNode && nodeSmartData.nodeId === activeNode.id.toString()) {
            setDisplayState(DisplayState.HasSmartData);
        } else if (nodeIsInFileQueue && nodeSmartData?.nodeId !== activeNode?.id.toString()) {
            setDisplayState(DisplayState.NodeInFileQueue);
        } else {
            setDisplayState(DisplayState.NoSmartData);
        }
    }, [nodeIsInFileQueue, nodeSmartData, activeNode]);

    return (
        <div className='border rounded h-100'>
            <SidesheetPage title={t(SmartDataTKeys.Title)} onClose={onClose} dataId='smartData-sideSheet-pane'>
                {!!isLoadingSmartData && !nodeIsInFileQueue ? (
                    <SmartDataSkeletonLoader />
                ) : (
                    <>
                        {displayState === DisplayState.NoSmartData && (
                            <div className='d-flex flex-column align-items-center mt-5 pt-3'>
                                <Text>{t(SmartDataTKeys.PreMetadataMessageLine1)}</Text>
                                <Text muted className='mb-4 mt-2 text-center'>
                                    {t(SmartDataTKeys.PreMetadataMessageLine2)}
                                </Text>
                                <Button onClick={queueItemForSmartDataExtraction} icon={isQueueing ? spinnerIconProps : undefined} className='mt-3'>
                                    {t(SmartDataTKeys.PreMetadataQueueItem)}
                                </Button>
                            </div>
                        )}
                        {displayState === DisplayState.NodeInFileQueue && (
                            <div className='d-flex flex-column align-items-center mt-5 pt-3'>
                                <Text>{t(SmartDataTKeys.PopulatingMetadata)}</Text>
                                <Text muted className='mb-4 mt-2'>
                                    {t(SmartDataTKeys.ThisMayTakeAMinute)}
                                </Text>
                                <Button primary disabled={fetchingSmartData} onClick={onRefreshClick} icon={fetchingSmartData ? spinnerIconProps : undefined}>
                                    {fetchingSmartData ? t(SmartDataTKeys.Refreshing) : t(SmartDataTKeys.Refresh)}
                                </Button>
                            </div>
                        )}
                        {displayState === DisplayState.HasSmartData && (
                            <>
                                <div className='d-flex'>
                                    <FormGroup>
                                        <Label for='promptSelect'>{t(SmartDataTKeys.PromptSelect)}</Label>
                                        <Input id='promptSelect' name='select' type='select' value={activeSmartDataItem?.promptVersion} onChange={(e) => handlePromptChange(e.target.value)}>
                                            {nodeSmartData?.smartDataItems.map((item) => (
                                                <option key={item.promptVersion} value={item.promptVersion}>
                                                    {item.promptVersion}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </div>
                                {activeSmartDataItem?.extractedData.map((item) => (
                                    <ExtractedValue key={item.id} extractedItem={item} userFeedback={activeSmartDataItem?.userFeedbackData.find((f) => f.extractedValueId == item.id)} onFeedbackChange={onFeedbackChange} />
                                ))}
                                <FullOutput fullOutput={activeSmartDataItem?.openAiResponse} userFeedback={activeSmartDataItem?.userFeedbackData.find((f) => f.isOpenAiResponseFeedback)} onFeedbackChange={onFeedbackChange} label={t(SmartDataTKeys.AiResponseOutput)} isTextOutput={false} isAIOutput={true} />
                                <FullOutput fullOutput={nodeSmartData?.fullTextOutput} userFeedback={activeSmartDataItem?.userFeedbackData.find((f) => f.isFullOutputFeedback)} onFeedbackChange={onFeedbackChange} label={t(SmartDataTKeys.FullOutput)} isTextOutput={true} isAIOutput={false} />
                            </>
                        )}
                    </>
                )}
            </SidesheetPage>
        </div>
    );
};
