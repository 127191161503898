import React, { ReactNode, useEffect, useState } from 'react';
import { NodeDetailsContext } from './NodeDetailsContext';
import { useFileVersionApi } from '../../../../api/fileVersions/useFileVersionApi';
import { useFilePasswordApi } from '../../../../api/filePassword/useFilePasswordApi';
import { useFileUtilities } from '../../../../hooks/useFileUtilities';
import { useNodeApi } from '../../../../api/node/useNodeApi';
import { NodeDTO } from '../../../../api/node/nodeApiTypes';
import { FileVersionDTO } from '../../../../api/fileVersions/fileVersionApiTypes';
import { useAccountFeatureValidator } from '../../../../hooks/useAccountFeatureValidator';
import { AccountFeatureEnum } from '../../../../api/accountFeatures/accountFeatureApiTypes';
import { NodeSmartDataDTO } from '../../../../api/smartData/smartDataApiTypes';
import { useSmartDataApi } from '../../../../api/smartData/useSmartDataApi';
import { useAuthContext } from '../../../../app/_context/AuthContext';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { ThemeEnum } from '../../../../hooks/useColors';
import { SmartDataTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { ToastMessage } from '../../../../hooks/useToastMessages';

export interface INodeDetailsContext {
    activeNode: NodeDTO | undefined;
    fileVersions: FileVersionDTO[];
    activeFileVersion: FileVersionDTO | undefined;
    isLoadingFileVersion: boolean;
    isLoadingNode: boolean;
    fetchFileVersions: (nodeId: string) => Promise<void>;
    fetchNode: (nodeId: string) => Promise<void>;
    nodeTypeIsNotAFile: boolean;
    isFileVersionModalOpen: boolean;
    setIsFileVersionModalOpen: (isFileVersionModalOpen: boolean) => void;
    hasFilePassword: boolean;
    onFilePasswordDelete: () => void;
    fetchNodeSmartDataForActiveNode: (displayValidationMessage?: boolean) => Promise<void>;
    nodeSmartData: NodeSmartDataDTO | undefined;
    hasSmartDataFeature: boolean;
    isLoadingSmartData: boolean;
}

interface NodeDetailsProviderProps {
    children: ReactNode;
}

export const NodeDetailsProvider = ({ children }: NodeDetailsProviderProps) => {
    const { t } = useSafeTranslation(TranslationFiles.SmartData);

    const { getNodeByIdAsync } = useNodeApi();
    const { getFileVersions } = useFileVersionApi();
    const { nodeTypeIsNotFile } = useFileUtilities();
    const { nodeHasFilePasswordAsync } = useFilePasswordApi();
    const { hasFeatureOnAccount } = useAccountFeatureValidator();
    const { getNodeSmartDataAsync } = useSmartDataApi();
    const { authUser, hasAuthUser } = useAuthContext();
    const { showToastMessage } = useLayerContext();

    const [activeNode, setActiveNode] = useState<NodeDTO>();
    const [isLoadingNode, setIsLoadingNode] = useState<boolean>(false);
    const [hasFilePassword, setHasFilePassword] = useState<boolean>(false);
    const [isLoadingFileVersion, setIsLoadingFileVersion] = useState<boolean>(false);
    const [activeFileVersion, setActiveFileVersion] = useState<FileVersionDTO | undefined>();
    const [fileVersions, setFileVersions] = useState<FileVersionDTO[]>([]);
    const [nodeTypeIsNotAFile, setNodeTypeIsNotAFile] = useState<boolean>(true);
    const [isFileVersionModalOpen, setIsFileVersionModalOpen] = useState<boolean>(false);
    const [nodeSmartData, setNodeSmartData] = useState<NodeSmartDataDTO>();
    const [isLoadingSmartData, setIsLoadingSmartData] = useState<boolean>(false);
    const [hasSmartDataFeature, setHasSmartDataFeature] = useState<boolean>(false);

    // This is needed to prevent a race condition that happens when a user clicks between different nodes quickly
    function verifyNodeId(nodeId: string) {
        return location.pathname.includes(nodeId);
    }

    const fetchFileVersions = async (nodeId: string) => {
        setIsLoadingFileVersion(true);
        try {
            const fileVersionsData = await getFileVersions(nodeId);
            setFileVersions(fileVersionsData);
            setActiveFileVersion(fileVersionsData.find((v: FileVersionDTO) => v.isActive));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoadingFileVersion(false);
        }
    };

    const fetchNode = async (nodeId: string) => {
        setIsLoadingNode(true);
        try {
            const nodeData = await getNodeByIdAsync(nodeId);

            if (verifyNodeId(nodeId)) {
                setActiveNode(nodeData);

                const isNotFile = nodeTypeIsNotFile(nodeData);
                setNodeTypeIsNotAFile(isNotFile);

                if (!isNotFile) {
                    setHasFilePassword(await nodeHasFilePasswordAsync(nodeId));
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoadingNode(false);
        }
    };

    const onFilePasswordDelete = async () => {
        if (!!activeNode) {
            setHasFilePassword(await nodeHasFilePasswordAsync(activeNode.id));
        }
    };

    const fetchNodeSmartDataForActiveNode = async (displayValidationMessage?: boolean) => {
        if (isMissingRequiredData(displayValidationMessage)) return;

        setIsLoadingSmartData(true);
        try {
            const response = await getNodeSmartDataAsync(activeNode!.id, authUser!.userID);
            if (response.smartDataItems.length > 0) {
                setNodeSmartData(response);
            } else {
                setNodeSmartData(undefined);
            }
        } catch (error) {
            console.log('Error fetching Smart Data:', error);
        } finally {
            setIsLoadingSmartData(false);
        }
    };

    const isMissingRequiredData = (displayValidationMessage = true) => {
        const toastData = {
            color: ThemeEnum.Danger,
            identifier: 'fetch-smart-data-validation-error',
            message: '',
        } as ToastMessage;

        const validationChecks: [boolean, string][] = [
            [!authUser, t(SmartDataTKeys.NoAuthUser)],
            [!hasSmartDataFeature, t(SmartDataTKeys.MissingAccountFeature)],
            [!activeNode, t(SmartDataTKeys.NoActiveNode)],
            [nodeTypeIsNotAFile, t(SmartDataTKeys.NodeIsNotAFile)],
        ];

        for (const [condition, message] of validationChecks) {
            if (condition) {
                toastData.message = message;
                break;
            }
        }

        if (!toastData.message) {
            return false;
        }

        displayValidationMessage && showToastMessage(toastData);
        return true;
    };

    useEffect(() => {
        if (!!hasAuthUser && !!authUser) {
            setHasSmartDataFeature(hasFeatureOnAccount(+authUser.accountID, AccountFeatureEnum.SmartDataExtraction));
        }
    }, [hasAuthUser]);

    const contextObject: INodeDetailsContext = {
        fetchFileVersions,
        fetchNode,
        activeNode,
        fileVersions,
        activeFileVersion,
        nodeTypeIsNotAFile,
        isLoadingFileVersion,
        isLoadingNode,
        isFileVersionModalOpen,
        setIsFileVersionModalOpen,
        hasFilePassword,
        onFilePasswordDelete,
        fetchNodeSmartDataForActiveNode,
        nodeSmartData,
        hasSmartDataFeature,
        isLoadingSmartData,
    };

    return <NodeDetailsContext.Provider value={contextObject}>{children}</NodeDetailsContext.Provider>;
};
