import React from 'react';
import { Col, Row } from 'reactstrap';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { Tooltip } from '@efilecabinet/nautilus-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MDFieldTypes } from '../../../../../api/mdFields/mdFieldApiTypes';
import { MDSideSheetInformationTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';

interface MDGroupDetailsFieldPropertyProps {
    fieldName: string;
    fieldType: MDFieldTypes;
    isAdded?: boolean;
    isInAccount?: boolean;
}

export const MDGroupDetailsFieldProperty = ({ fieldName, fieldType, isAdded, isInAccount } : MDGroupDetailsFieldPropertyProps) => {

    const { t } = useSafeTranslation(TranslationFiles.MDSideSheetInformation);

    function getFieldStatusIcon(): IconProp {
        if (isInAccount) {
            return ['fas', 'circle-check'];
        } else if (isAdded) {
            return ['fas', 'circle-check'];
        } else {
            return ['far', 'circle'];
        }
    }

    return (
        <Row className='mb-3 flex-grow-1'>
            <Row className='d-flex justify-content-start align-items-start'>
                <Col className='col-1'>
                    {!!isInAccount ? (
                        <Tooltip title={t(MDSideSheetInformationTKeys.TooltipFieldInAccount)}>
                            <FontAwesomeIcon icon={getFieldStatusIcon()} className='fa-sharp m-0' color={isAdded ? '#1E9B3E' : '#8E8E8E'} />
                        </Tooltip>
                    ) : (
                        <FontAwesomeIcon icon={getFieldStatusIcon()} className='fa-sharp m-0' color={isAdded ? '#1E9B3E' : '#8E8E8E'} />
                    )}
                </Col>
                <Col className='text-align-start pb-1'>
                    <Text>{fieldName}</Text>
                </Col>
            </Row>
            <Row>
                <Text className='ms-4' color='#8E8E8E' size='md' italic muted>
                    {[MDFieldTypes[fieldType ?? 0]]}
                </Text>
            </Row>
        </Row>
    );
};
