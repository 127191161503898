import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Page, Text } from '@efilecabinet/efc-atlantis-components';
import { useNodeDetailsContext } from '../../../components/NodeSideSheet/_context/NodeDetailsContext/NodeDetailsContext';
import { Col, Row } from 'reactstrap';
import { Previewer } from '../../../components/Previewer/Previewer';
import { SmartData } from '../../../components/NodeSideSheet/Tabs/SmartData/SmartData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIcons } from '../../../hooks/useIcons';
import { useColors } from '../../../hooks/useColors';
import { useFileUtilities } from '../../../hooks/useFileUtilities';
import zeroStateImg from '../../../assets/images/pageNotFound.svg';
import { SmartDataTKeys, TranslationFiles, useSafeTranslation } from '../../../hooks/useSafeTranslation';

export const SmartDataExtractionPage = () => {
    const { t } = useSafeTranslation(TranslationFiles.SmartData);
    const { nodeId } = useParams();
    const { activeNode, isLoadingNode, fetchNode } = useNodeDetailsContext();
    const { spinnerIconProps } = useIcons();
    const { atlantisPrimary } = useColors();
    const { nodeIsContainer } = useFileUtilities();

    const [initializePageData, setInitializePageData] = useState<boolean>(false);

    const containerDiv = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!activeNode && !!nodeId) {
            fetchNode(nodeId);
        }
    }, [activeNode]);

    useEffect(() => {
        if (!!activeNode && activeNode.id === nodeId && !nodeIsContainer(activeNode.systemType)) {
            setInitializePageData(true);
        }
    }, [activeNode]);

    return (
        <Page title={'Smart Data Extraction'} icon={{ icon: 'sparkles' }}>
            <Row className='h-100'>
                {isLoadingNode && (
                    <>
                        <div className='d-flex align-items-center'>
                            <FontAwesomeIcon {...spinnerIconProps} color={atlantisPrimary} />
                            <Text className=''>Loading...</Text>
                        </div>
                    </>
                )}
                {initializePageData && activeNode && (
                    <>
                        <Col sm='8' style={{maxHeight: '90vh', minHeight: '475px', overflowY: 'auto'}} className='mb-3 mb-md-0'>
                            <Previewer previewerNode={activeNode} fileVersionId={activeNode?.fileInfo?.id} isLoading={isLoadingNode} previewerContainer={containerDiv} />
                        </Col>
                        <Col>
                            <SmartData />
                        </Col>
                    </>
                )}
                {!isLoadingNode && activeNode && nodeIsContainer(activeNode.systemType) && (
                    <div className='d-flex flex-column align-items-center justify-content-center w-100'>
                        <Text className='mt-3 px-3 text-center' tag='h3'>
                            {t(SmartDataTKeys.NodeIsNotAFile)}
                        </Text>
                        <div style={{ maxWidth: '500px' }}>
                            <img src={zeroStateImg} className='mt-5' style={{ width: '100%', height: 'auto' }} alt={t(SmartDataTKeys.NodeIsNotAFile)} />
                        </div>
                    </div>
                )}
            </Row>
        </Page>
    );
};
