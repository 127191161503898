import React from 'react';
import { Col, Row } from 'reactstrap';
import { Text } from '@efilecabinet/efc-atlantis-components';

interface MDGroupDetailsFieldPropertyBasicProps {
    fieldName: string;
}

export const MDGroupDetailsFieldPropertyBasic = ({ fieldName }: MDGroupDetailsFieldPropertyBasicProps) => {
    return (
        <Row className='mb-3 flex-grow-1'>
            <Row className='d-flex justify-content-start align-items-start'>
                <Col className='text-align-start pb-1'>
                    <Text>{fieldName}</Text>
                </Col>
            </Row>
        </Row>
    );
};
