import { MetadataGroupDecorDto, MetadataGroupDto } from '../../api/mdGroups/mdGroupsApiTypes';
import { useMDGroupsApi } from '../../api/mdGroups/useMDGroupsApi';
import { useMDLibraryGroupsApi } from '../../api/mdLibrary/useMDLibraryGroupsApi';

export const useMetadataGroupUtilities = () => {
    const BATCH_SIZE = 100;

    const { queryMdGroupsAsync } = useMDGroupsApi();
    const { queryMdLibraryGroupsAsync } = useMDLibraryGroupsApi();

    function createEmptyMdGroup() {
        return { id: '', name: '', accountId: '', metadataFields: [] };
    }

    async function loadAllMdAccountGroupsAsync(accountId: number) {
        const currentGroups: MetadataGroupDecorDto[] = [];
        let allGroupsLoaded = false;

        while (!allGroupsLoaded) {
            await queryMdGroupsAsync(accountId, currentGroups.length, BATCH_SIZE)
                .then((groups) => {
                    if (!!groups) {
                        if (groups.length < BATCH_SIZE) {
                            allGroupsLoaded = true;
                        }
                        currentGroups.push(...groups);
                    } else {
                        allGroupsLoaded = true;
                    }
                });
        }

        return currentGroups;
    }

    async function loadAllMdLibraryGroupsAsync() {
        const currentGroups: MetadataGroupDto[] = [];
        let allGroupsLoaded = false;

        while (!allGroupsLoaded) {
            await queryMdLibraryGroupsAsync(currentGroups.length, BATCH_SIZE)
                .then((groups) => {
                    if (!!groups) {
                        if (groups.length < BATCH_SIZE) {
                            allGroupsLoaded = true;
                        }
                        currentGroups.push(...groups);
                    } else {
                        allGroupsLoaded = true;
                    }
                });
        }

        return currentGroups;
    }

    return {
        createEmptyMdGroup,
        loadAllMdAccountGroupsAsync,
        loadAllMdLibraryGroupsAsync,
    };
};