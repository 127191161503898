import React from 'react';
import { Route, Routes } from 'react-router';
import { MainLayout } from '../../../components/Layout/MainLayout';
import { RoutePath } from '../routingTypes';
import { PageNotFound } from '../../_errorHandling/PageNotFound/PageNotFound';
import { HomePage } from '../../_home';
import { AuthRoute } from '../../_auth';
import { AccountSettingsPage, UserSettingsPage } from '../../_settings';
import { AuditLogsPage } from '../../_auditLogs';
import { CheckedOutPage } from '../../_checkOut/CheckedOutPage/CheckedOutPage';
import { DocumentsPage } from '../../_documents/DocumentsPage/DocumentsPage';
import { DocumentRequestsPage } from '../../_documentRequests/DocumentRequestsPage/DocumentRequestsPage';
import { ESignaturePage } from '../../_eSignature/ESignaturePage/ESignaturePage';
import { FileProcessingQueuePage } from '../../_fileProcessing/FileProcessingQueuePage/FileProcessingQueuePage';
import { FolderTemplatesPage } from '../../_folderTemplates/FolderTemplatesPage/FolderTemplatesPage';
import { FormsPage } from '../../_formFill/FormsPage/FormsPage';
import { GovernancePage } from '../../_governance/GovernancePage/GovernancePage';
import { MetadataManagementPage } from '../../_metadata/_management/MetadataManagementPage/MetadataManagementPage';
import { ProfileManagementPage } from '../../_profiles/ProfileManagementPage/ProfileManagementPage';
import { RecentsPage } from '../../_recents';
import { RecycleBinPage } from '../../_recycleBin/RecycleBinPage/RecycleBinPage';
import { ReportsPage } from '../../_reporting/ReportsPage/ReportsPage';
import { SalesforcePage } from '../../_salesforce';
import { SearchPage } from '../../_search/SearchPage/SearchPage';
import { SecurityPoliciesPage } from '../../_securityPolicies';
import { SmartDataExtractionPage } from '../../_smartDataExtraction';
import { SystemLogsPage } from '../../_logging';
import { ToDoListPage } from '../../_toDoList/ToDoListPage/ToDoListPage';
import { UsersPage } from '../../_people/UsersPage/UsersPage';
import { UtilitiesPage } from '../../_utilities';
import { WorkflowPage } from '../../_workflow/WorkflowPage/WorkflowPage';
import { WorkYouCareAboutPage } from '../../_workYouCareAbout/WorkYouCareAboutPage/WorkYouCareAboutPage';
import { NodeDetailsProvider } from '../../../components/NodeSideSheet/_context/NodeDetailsContext/NodeDetailsProvider';

export const MainLayoutRoutes = () => {

    return (
        <MainLayout>
            {/* prettier-ignore */}
            <Routes>

                <Route path="*" element={<PageNotFound />} />

                <Route index element={<HomePage />} />

                <Route path={RoutePath.AccountSettingsIndex} element={<AuthRoute path={RoutePath.AccountSettingsIndex}><AccountSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.AccountSettings} element={<AuthRoute path={RoutePath.AccountSettings}><AccountSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.SingleSignOn} element={<AuthRoute path={RoutePath.SingleSignOn}><AccountSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.SamlValidate} element={<AuthRoute path={RoutePath.SamlValidate}><AccountSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.SamlNew} element={<AuthRoute path={RoutePath.SamlNew}><AccountSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.SamlEdit} element={<AuthRoute path={RoutePath.SamlEdit}><AccountSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.Branding} element={<AuthRoute path={RoutePath.Branding}><AccountSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.AccountEmailImports} element={<AuthRoute path={RoutePath.AccountEmailImports}><AccountSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.AccountEmailImportNew} element={<AuthRoute path={RoutePath.AccountEmailImportNew}><AccountSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.AccountEmailImportEdit} element={<AuthRoute path={RoutePath.AccountEmailImportEdit}><AccountSettingsPage /></AuthRoute>} />

                <Route path={RoutePath.AuditLogs} element={<AuthRoute path={RoutePath.AuditLogs}><AuditLogsPage /></AuthRoute>} />

                <Route path={RoutePath.CheckedOutIndex} element={<AuthRoute path={RoutePath.CheckedOutIndex}><CheckedOutPage /></AuthRoute>} />
                <Route path={RoutePath.CheckedOutPersonal} element={<AuthRoute path={RoutePath.CheckedOutPersonal}><CheckedOutPage /></AuthRoute>} />
                <Route path={RoutePath.CheckedOutAccount} element={<AuthRoute path={RoutePath.CheckedOutAccount}><CheckedOutPage /></AuthRoute>} />

                <Route path={RoutePath.Documents} element={<AuthRoute path={RoutePath.Documents}><DocumentsPage /></AuthRoute>} />
                <Route path={RoutePath.Node} element={<AuthRoute path={RoutePath.Node}><DocumentsPage /></AuthRoute>} />
                <Route path={RoutePath.NodeDetail} element={<AuthRoute path={RoutePath.NodeDetail}><DocumentsPage /></AuthRoute>} />
                <Route path={RoutePath.NodeContainer} element={<AuthRoute path={RoutePath.NodeContainer}><DocumentsPage /></AuthRoute>} />
                <Route path={RoutePath.Collections} element={<AuthRoute path={RoutePath.Collections}><DocumentsPage /></AuthRoute>} />
                <Route path={RoutePath.Collection} element={<AuthRoute path={RoutePath.Collection}><DocumentsPage /></AuthRoute>} />
                <Route path={RoutePath.CollectionNodeDetail} element={<AuthRoute path={RoutePath.CollectionNodeDetail}><DocumentsPage /></AuthRoute>} />

                <Route path={RoutePath.DocumentRequestIndex} element={<AuthRoute path={RoutePath.DocumentRequestIndex}><DocumentRequestsPage /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestInbox} element={<AuthRoute path={RoutePath.DocumentRequestInbox}><DocumentRequestsPage /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestNew} element={<AuthRoute path={RoutePath.DocumentRequestNew}><DocumentRequestsPage /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestInboxEdit} element={<AuthRoute path={RoutePath.DocumentRequestInboxEdit}><DocumentRequestsPage /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestTemplates} element={<AuthRoute path={RoutePath.DocumentRequestTemplates}><DocumentRequestsPage /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestTemplateNew} element={<AuthRoute path={RoutePath.DocumentRequestTemplateNew}><DocumentRequestsPage /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestTemplateEdit} element={<AuthRoute path={RoutePath.DocumentRequestTemplateEdit}><DocumentRequestsPage /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestSent} element={<AuthRoute path={RoutePath.DocumentRequestSent}><DocumentRequestsPage /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestSentEdit} element={<AuthRoute path={RoutePath.DocumentRequestSentEdit}><DocumentRequestsPage /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestAccountRequests} element={<AuthRoute path={RoutePath.DocumentRequestAccountRequests}><DocumentRequestsPage /></AuthRoute>} />
                <Route path={RoutePath.DocumentRequestAccountRequestEdit} element={<AuthRoute path={RoutePath.DocumentRequestAccountRequestEdit}><DocumentRequestsPage /></AuthRoute>} />

                <Route path={RoutePath.ESignatureIndex} element={<AuthRoute path={RoutePath.ESignatureIndex}><ESignaturePage /></AuthRoute>} />
                <Route path={RoutePath.ESignatureSent} element={<AuthRoute path={RoutePath.ESignatureSent}><ESignaturePage /></AuthRoute>} />
                <Route path={RoutePath.ESignatureAll} element={<AuthRoute path={RoutePath.ESignatureAll}><ESignaturePage /></AuthRoute>} />
                <Route path={RoutePath.ESignatureTemplates} element={<AuthRoute path={RoutePath.ESignatureTemplates}><ESignaturePage /></AuthRoute>} />

                <Route path={RoutePath.PageNotFound} element={<AuthRoute path={RoutePath.PageNotFound}><PageNotFound /></AuthRoute>} />

                <Route path={RoutePath.FileProcessingQueue} element={<AuthRoute path={RoutePath.FileProcessingQueue}><FileProcessingQueuePage /></AuthRoute>} />

                <Route path={RoutePath.SmartDataExtraction} element={<AuthRoute path={RoutePath.SmartDataExtraction}><SmartDataExtractionPage /></AuthRoute> } />

                <Route path={RoutePath.FolderTemplatesIndex} element={<AuthRoute path={RoutePath.FolderTemplatesIndex}><FolderTemplatesPage /></AuthRoute>} />
                <Route path={RoutePath.FolderTemplateLibrary} element={<AuthRoute path={RoutePath.FolderTemplateLibrary}><FolderTemplatesPage /></AuthRoute>} />
                <Route path={RoutePath.FolderTemplateEdit} element={<AuthRoute path={RoutePath.FolderTemplateEdit}><FolderTemplatesPage /></AuthRoute>} />

                <Route path={RoutePath.Forms} element={<AuthRoute path={RoutePath.Forms}><FormsPage /></AuthRoute>} />

                <Route path={RoutePath.Governance} element={<AuthRoute path={RoutePath.Governance}><GovernancePage /></AuthRoute>} />

                <Route path={RoutePath.MetadataIndex} element={<AuthRoute path={RoutePath.MetadataIndex}><MetadataManagementPage /></AuthRoute>} />
                <Route path={RoutePath.MDFields} element={<AuthRoute path={RoutePath.MDFields}><MetadataManagementPage /></AuthRoute>} />
                <Route path={RoutePath.MDGroups} element={<AuthRoute path={RoutePath.MDGroups}><MetadataManagementPage /></AuthRoute>} />

                <Route path={RoutePath.ProfilesIndex} element={<AuthRoute path={RoutePath.ProfilesIndex}><ProfileManagementPage /></AuthRoute>} />
                <Route path={RoutePath.Profiles} element={<AuthRoute path={RoutePath.Profiles}><ProfileManagementPage /></AuthRoute>} />
                <Route path={RoutePath.ProfileEdit} element={<AuthRoute path={RoutePath.ProfileEdit}><ProfileManagementPage /></AuthRoute>} />
                <Route path={RoutePath.ProfileNew} element={<AuthRoute path={RoutePath.ProfileNew}><ProfileManagementPage /></AuthRoute>} />
                <Route path={RoutePath.ProfileItems} element={<AuthRoute path={RoutePath.ProfileItems}><ProfileManagementPage /></AuthRoute>} />
                <Route path={RoutePath.ProfileItemEdit} element={<AuthRoute path={RoutePath.ProfileItemEdit}><ProfileManagementPage /></AuthRoute>} />
                <Route path={RoutePath.ProfileItemNew} element={<AuthRoute path={RoutePath.ProfileItemNew}><ProfileManagementPage /></AuthRoute>} />

                <Route path={RoutePath.Recents} element={<AuthRoute path={RoutePath.Recents}><RecentsPage /></AuthRoute>} />

                <Route path={RoutePath.RecycleBin} element={<AuthRoute path={RoutePath.RecycleBin}><RecycleBinPage /></AuthRoute>} />

                <Route path={RoutePath.Reports} element={<AuthRoute path={RoutePath.Reports}><ReportsPage /></AuthRoute>} />

                <Route path={RoutePath.Salesforce} element={<AuthRoute path={RoutePath.Salesforce}><SalesforcePage /></AuthRoute>} />
                <Route path={RoutePath.SalesforceMappingEdit} element={<AuthRoute path={RoutePath.SalesforceMappingEdit}><SalesforcePage /></AuthRoute>} />
                <Route path={RoutePath.SalesforceMappingNew} element={<AuthRoute path={RoutePath.SalesforceMappingNew}><SalesforcePage /></AuthRoute>} />

                <Route path={RoutePath.Search} element={<AuthRoute path={RoutePath.Search}><SearchPage /></AuthRoute>} />
                <Route path={RoutePath.UserSearch} element={<AuthRoute path={RoutePath.UserSearch}><SearchPage /></AuthRoute>} />
                <Route path={RoutePath.SharedSearch} element={<AuthRoute path={RoutePath.SharedSearch}><SearchPage /></AuthRoute>} />

                <Route path={RoutePath.SecurityPolicies} element={<AuthRoute path={RoutePath.SecurityPolicies}><SecurityPoliciesPage /></AuthRoute>} />
                <Route path={RoutePath.SecurityPolicyEdit} element={<AuthRoute path={RoutePath.SecurityPolicyEdit}><SecurityPoliciesPage /></AuthRoute>} />
                <Route path={RoutePath.SecurityPolicyNew} element={<AuthRoute path={RoutePath.SecurityPolicyNew}><SecurityPoliciesPage /></AuthRoute>} />

                <Route path={RoutePath.SystemLogsServer} element={<AuthRoute path={RoutePath.SystemLogsServer}><SystemLogsPage /></AuthRoute>} />
                <Route path={RoutePath.SystemLogsClient} element={<AuthRoute path={RoutePath.SystemLogsClient}><SystemLogsPage /></AuthRoute>} />

                <Route path={RoutePath.OpenToDos} element={<AuthRoute path={RoutePath.OpenToDos}><ToDoListPage /></AuthRoute>} />
                <Route path={RoutePath.CompletedToDos} element={<AuthRoute path={RoutePath.CompletedToDos}><ToDoListPage /></AuthRoute>} />
                <Route path={RoutePath.DismissedToDos} element={<AuthRoute path={RoutePath.DismissedToDos}><ToDoListPage /></AuthRoute>} />

                <Route path={RoutePath.UserSettingsIndex} element={<AuthRoute path={RoutePath.UserSettingsIndex}><UserSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.UserSettings} element={<AuthRoute path={RoutePath.UserSettings}><UserSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.MyAccounts} element={<AuthRoute path={RoutePath.MyAccounts}><UserSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.IntegratedProducts} element={<AuthRoute path={RoutePath.IntegratedProducts}><UserSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.UserEmailImports} element={<AuthRoute path={RoutePath.UserEmailImports}><UserSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.UserEmailImportNew} element={<AuthRoute path={RoutePath.UserEmailImportNew}><UserSettingsPage /></AuthRoute>} />
                <Route path={RoutePath.UserEmailImportEdit} element={<AuthRoute path={RoutePath.UserEmailImportEdit}><UserSettingsPage /></AuthRoute>} />

                <Route path={RoutePath.UserIndex} element={<AuthRoute path={RoutePath.UserIndex}><UsersPage /></AuthRoute>} />
                <Route path={RoutePath.Users} element={<AuthRoute path={RoutePath.Users}><UsersPage /></AuthRoute>} />
                <Route path={RoutePath.UserNew} element={<AuthRoute path={RoutePath.UserNew}><UsersPage /></AuthRoute>} />
                <Route path={RoutePath.UserEdit} element={<AuthRoute path={RoutePath.UserEdit}><UsersPage /></AuthRoute>} />
                <Route path={RoutePath.Groups} element={<AuthRoute path={RoutePath.Groups}><UsersPage /></AuthRoute>} />
                <Route path={RoutePath.GroupNew} element={<AuthRoute path={RoutePath.GroupNew}><UsersPage /></AuthRoute>} />
                <Route path={RoutePath.GroupEdit} element={<AuthRoute path={RoutePath.GroupEdit}><UsersPage /></AuthRoute>} />
                <Route path={RoutePath.UserSessions} element={<AuthRoute path={RoutePath.UserSessions}><UsersPage /></AuthRoute>} />
                <Route path={RoutePath.AccessLinks} element={<AuthRoute path={RoutePath.AccessLinks}><UsersPage /></AuthRoute>} />
                <Route path={RoutePath.AccessLinkEdit} element={<AuthRoute path={RoutePath.AccessLinkEdit}><UsersPage /></AuthRoute>} />
                <Route path={RoutePath.UserDirectories} element={<AuthRoute path={RoutePath.UserDirectories}><UsersPage /></AuthRoute>} />

                <Route path={RoutePath.UtilitiesIndex} element={<AuthRoute path={RoutePath.UtilitiesIndex}><UtilitiesPage /></AuthRoute>} />
                <Route path={RoutePath.CSVImport} element={<AuthRoute path={RoutePath.CSVImport}><UtilitiesPage /></AuthRoute>} />
                <Route path={RoutePath.CSVExport} element={<AuthRoute path={RoutePath.CSVExport}><UtilitiesPage /></AuthRoute>} />
                <Route path={RoutePath.AccountTemplateExport} element={<AuthRoute path={RoutePath.AccountTemplateExport}><UtilitiesPage /></AuthRoute>} />
                <Route path={RoutePath.AccountTemplateImport} element={<AuthRoute path={RoutePath.AccountTemplateImport}><UtilitiesPage /></AuthRoute>} />

                <Route path={RoutePath.WorkflowIndex} element={<AuthRoute path={RoutePath.WorkflowIndex}><WorkflowPage /></AuthRoute>} />
                <Route path={RoutePath.Workflows} element={<AuthRoute path={RoutePath.Workflows}><WorkflowPage /></AuthRoute>} />
                <Route path={RoutePath.WorkflowEdit} element={<AuthRoute path={RoutePath.WorkflowEdit}><WorkflowPage /></AuthRoute>} />
                <Route path={RoutePath.WorkflowInstances} element={<AuthRoute path={RoutePath.WorkflowInstances}><WorkflowPage /></AuthRoute>} />
                <Route path={RoutePath.WorkflowInstanceEdit} element={<AuthRoute path={RoutePath.WorkflowInstanceEdit}><WorkflowPage /></AuthRoute>} />
                <Route path={RoutePath.WorkflowDashboard} element={<AuthRoute path={RoutePath.WorkflowDashboard}><WorkflowPage /></AuthRoute>} />
                <Route path={RoutePath.WorkflowHistory} element={<AuthRoute path={RoutePath.WorkflowHistory}><WorkflowPage /></AuthRoute>} />

                <Route path={RoutePath.WorkYouCareAbout} element={<AuthRoute path={RoutePath.WorkYouCareAbout}><WorkYouCareAboutPage /></AuthRoute>} />

            </Routes>
        </MainLayout>
    );
};
