import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { MetadataGroupDecorDto, MetadataGroupDto } from '../mdGroups/mdGroupsApiTypes';

export const useMDLibraryGroupsApi = () => {

    const getMdLibraryGroupAsync = async (metadataLibraryGroupId: string): Promise<MetadataGroupDecorDto> => {
        const resp: AxiosResponse = await Api.get(`api/metadataLibraryGroup/${metadataLibraryGroupId}`);
        return resp.data as MetadataGroupDecorDto;
    };

    const queryMdLibraryGroupsAsync = async (start: number, count: number): Promise<MetadataGroupDto[]> => {
        const resp: AxiosResponse = await Api.get(`api/metadataLibraryGroup/${start}/${count}`);
        return resp.data as MetadataGroupDto[];
    };

    return {
        getMdLibraryGroupAsync,
        queryMdLibraryGroupsAsync,
    };
};