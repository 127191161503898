import React, { ReactNode, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { useColors } from '../../../../hooks/useColors';
import { MDSideSheetInformationTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import '../../../../components/NestedCollapse/NestedCollapse.css';

interface GroupDetailsNestedCollapseProps {
    children: ReactNode;
    semibold?: boolean;
    bold?: boolean;
    tooltipMessage?: string;
    className?: string;
    innerClassName?: string;
    openByDefault?: boolean;
    color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'muted' | 'default';
}

//TODO: Switch to Nautilus component when it's ready
export const GroupDetailsNestedCollapse = ({ children, className, innerClassName, openByDefault = true }: GroupDetailsNestedCollapseProps) => {

    const { atlantisDefault } = useColors();
    const { t } = useSafeTranslation(TranslationFiles.MDSideSheetInformation);

    const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false);
    const [animationClass, setAnimationClass] = useState<string>('');

    const ANIMATION_DELAY_IN_MS = 380;

    const grow = () => {
        setIsCollapseOpen(true);
        setAnimationClass('grow-animation');
    };

    const onClick = () => {
        if (isCollapseOpen) {
            setAnimationClass('shrink-animation');
            setTimeout(() => {
                setIsCollapseOpen(false);
            }, ANIMATION_DELAY_IN_MS);
        } else {
            grow();
        }
    };

    useEffect(() => {
        if (openByDefault) {
            grow();
        }
    }, [openByDefault]);

    return (
        <>
            <Row className={`${className || ''}`}>
                <Col xs='auto' role='button' className='mb-2 d-flex align-items-center' onClick={onClick}>
                    <Text size={'lg'} semibold={true} color={atlantisDefault}>
                        {t(MDSideSheetInformationTKeys.GroupDetailsCollapseTitle)}
                    </Text>
                </Col>
            </Row>
            {!!isCollapseOpen && (
                <Row className='h-auto'>
                    <Col xs='11' className={`collapse-md-group-content ${animationClass} ${innerClassName || ''}`}>
                        {children}
                    </Col>
                </Row>
            )}
        </>
    );
};
