import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { NodeSmartDataDTO, UserFeedback } from './smartDataApiTypes';

export const useSmartDataApi = () => {

    const getNodeSmartDataAsync = async (nodeId: string, userId: number): Promise<NodeSmartDataDTO> => {
        const resp: AxiosResponse = await Api.get(`api/SmartData/GetNodeSmartData/${nodeId}/${userId}`);
        return resp.data as NodeSmartDataDTO;
    };

    const addOrPatchUserFeedback = async (userFeedback: UserFeedback): Promise<UserFeedback> => {
        const resp: AxiosResponse = await Api.post('api/SmartData/AddOrPatchUserFeedback', userFeedback);
        return resp.data as UserFeedback;
    };

    return {
        getNodeSmartDataAsync,
        addOrPatchUserFeedback,
    };
};