import { useNavigate } from 'react-router';
import { RoutePath } from '../routingTypes';

export const useSmartDataRouting = () => {

    const navigate = useNavigate();

    const getLinkToSmartData = (nodeId: string) => {
        return RoutePath.SmartDataExtraction.replace(':nodeId', nodeId);
    };

    const routeToSmartDataExtraction = (nodeId: string) => {
        navigate(getLinkToSmartData(nodeId));
    };

    return {
        getLinkToSmartData,
        routeToSmartDataExtraction,
    };
};
