import React, { Dispatch, SetStateAction } from 'react';
import { TextButton, AsyncButton } from '@efilecabinet/nautilus-ui';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { useSafeTranslation, TranslationFiles, MDLibraryModalTKeys } from '../../../../../../hooks/useSafeTranslation';

export interface MdLibraryModalFooterProps {
    message: string;
    onCancel: () => void;
    onSubmit: () => void;
    isProcessingSubmitState: [boolean, Dispatch<SetStateAction<boolean>>];
}

export const MdLibraryModalFooter = ({ message, onCancel, onSubmit, isProcessingSubmitState }: MdLibraryModalFooterProps) => {

    const { t } = useSafeTranslation(TranslationFiles.MDLibraryModal);

    return (
        <>
            <Text color='primary'>{message}</Text>
            <TextButton
                buttonStyle='btn-outline-primary'
                buttonType='textButton'
                label={t(MDLibraryModalTKeys.CancelCta)}
                onClick={onCancel}
                dataId='btnCancelMDFieldLibrary'/>
            <AsyncButton
                buttonStyle='btn-primary'
                buttonType='asyncButton'
                label={t(MDLibraryModalTKeys.ConfirmCta)}
                disabled={!message}
                icon={{ icon: ['fas', 'spinner-third'], pulse: true }}
                width='150px'
                isProcessingActionState={isProcessingSubmitState}
                onClick={onSubmit}
                dataId='btnConfirmMDFieldLibrary' />
        </>
    );
};