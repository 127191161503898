import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { AccountDTO } from './accountApiTypes';

export const useAccountApi = () => {

    const updateAccountNameInUtopia = async (accountId: number, name: string): Promise<AccountDTO> => {
        const encodedName = encodeURIComponent(name);
        const resp: AxiosResponse = await Api.get(`api/Account/UpdateName/${accountId}?name=${encodedName}`);
        return resp.data;
    };

    return {
        updateAccountNameInUtopia,
    };
};
