import React, { Dispatch, SetStateAction, useEffect, useMemo, useState, useCallback } from 'react';
import { Row } from 'reactstrap';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import { Modal } from '@efilecabinet/nautilus-ui';
import { useAuthContext } from '../../../../../app/_context/AuthContext';
import { useMetadataFieldUtilities } from '../../../../../hooks/metadata/useMetadataFieldUtilities';
import { useSafeTranslation, TranslationFiles, MDLibraryModalTKeys } from '../../../../../hooks/useSafeTranslation';
import { useMDFieldApi } from '../../../../../api/mdFields/useMDFieldApi';
import { MetadataFieldDto, MDFieldTypes, MetadataFieldDecorDto } from '../../../../../api/mdFields/mdFieldApiTypes';
import { MDLibraryHeader } from '../__shared/MdLibraryHeader/MdLibraryHeader';
import { MDSideSheetCard } from '../../../_management/_sidesheet/MdSideSheetCard/MdSideSheetCard';
import { MDLibraryMainCard } from '../__shared/MdLibraryMainCard/MdLibraryMainCard';
import { MDFieldSideSheetContent } from '../../../_management/_sidesheet/MdFieldSideSheetContent/MdFieldSideSheetContent';
import { MdLibraryAddItemCta, MdLibraryAddItemCtaProps } from '../__shared/MdLibraryAddItemCta/MdLibraryAddItemCta';
import { MdLibraryModalFooter } from '../__shared/MdLibraryModalFooter/MdLibraryModalFooter';
import '../__shared/MdLibraryModal.css';

export interface MDLibraryFieldsModalProps {
    isShowingState: [boolean, Dispatch<SetStateAction<boolean>>];
}

export const MDLibraryFieldsModal = ({ isShowingState }: MDLibraryFieldsModalProps) => {

    const { authUser, hasAuthUser } = useAuthContext();
    const { createMdFieldAsync } = useMDFieldApi();
    const { loadAllMdAccountFieldsAsync, loadAllMdLibraryFieldsAsync } = useMetadataFieldUtilities();
    const { t } = useSafeTranslation(TranslationFiles.MDLibraryModal);
    
    // Backend data
    const [accountFields, setAccountFields] = useState<MetadataFieldDecorDto[]>();
    const [libraryFields, setLibraryFields] = useState<MetadataFieldDto[]>();
    
    // Local state
    const [selectedField, setSelectedField] = useState<MetadataFieldDto>();
    const [fieldsToAdd, setFieldsToAdd] = useState<MetadataFieldDto[]>([]);
    
    // Modal state
    const [isProcessingSubmission, setIsProcessingSubmission] = useState(false);
    const [isShowing, setIsShowing] = isShowingState;

    const rowData: MetadataFieldDto[] | null = useMemo(() => {
        if (!libraryFields) {
            return null;
        } else {
            return libraryFields;
        }
    }, [libraryFields]);

    const columnDefs: ColDef<MetadataFieldDto>[] = useMemo(() => {
        const addItemCtaProps: MdLibraryAddItemCtaProps = {
            isItemInAccount: isFieldInAccount,
            isItemAdded: isFieldAdded,
            addItem: addField,
            removeItem: removeField
        };

        return [
            {
                field: 'name',
                headerName: t(MDLibraryModalTKeys.NameColumn),
                flex: 3
            },
            {
                field: 'type',
                headerName: t(MDLibraryModalTKeys.TypeColumn),
                flex: 1,
                valueFormatter: ({ value }) => (MDFieldTypes[value] ?? ''),
            },
            {
                flex: 2,
                headerName: t(MDLibraryModalTKeys.AddToAccountColumn),
                cellRenderer: MdLibraryAddItemCta,
                cellRendererParams: addItemCtaProps,
                cellStyle: () => ({ display: 'flex', alignItems: 'center' }),
                sortable: false,
            },
        ];
    }, [libraryFields, accountFields, fieldsToAdd]);
    
    function isFieldInAccount(name: string) {
        return !!accountFields?.find(field => field.name === name);
    }

    function isFieldAdded(id: string) {
        return !!fieldsToAdd?.find(field => field.id === id);
    }
    
    function addField(id: string) {
        setFieldsToAdd([...fieldsToAdd, libraryFields?.find((field) => (field.id == id)) as MetadataFieldDto]);
    }
    
    function removeField(id: string) {
        setFieldsToAdd(fieldsToAdd.filter((field) => field.id !== id));
    }

    function onRowSelected(event: RowSelectedEvent<MetadataFieldDto>) {
        const rowData = event.data;
        if (!!rowData && event.node.isSelected()) {
            setSelectedField(libraryFields?.find((f) => f.id === rowData.id) as MetadataFieldDto);
        }
    }

    const onClose = () => setIsShowing(false);

    const onSubmit = useCallback(() => {
        if (!!authUser) {
            setIsProcessingSubmission(true);

            const updatedFieldsToAdd = fieldsToAdd.map((field) => ({ ...field, id: '', accountId: authUser.accountID.toString() }));
    
            Promise.all(updatedFieldsToAdd.map((field) => createMdFieldAsync(field)))
                .then(() => {
                    onClose();
                })
                .catch(() => {
                    // TODO: Need to have more robust error handling in Atlantis
                    console.error('Error creating fields');
                })
                .finally(() => {
                    setIsProcessingSubmission(false);
                });
        }
    }, [hasAuthUser]);
    
    useEffect(() => {
        if (!!authUser) {
            loadAllMdLibraryFieldsAsync().then((fields) => setLibraryFields(fields));
            loadAllMdAccountFieldsAsync(authUser.accountID).then((fields) => setAccountFields(fields));
        }
    }, [hasAuthUser]);

    return (
        <Modal
            options={{ size: 'modal-xl', modalContentClassName: 'p-0' }}
            header={{
                className: 'p-0',
                children:
                    <MDLibraryHeader
                        title={`${t(MDLibraryModalTKeys.ModalTitle) + ' ' + t(MDLibraryModalTKeys.Field)}`}
                        handleClose={onClose} />
            }}
            body={{
                className: 'p-0',
                children:
                    <div className='library-modal-body modal-body' >
                        <Row className='h-100 px-2 flex-nowrap'>
                            <MDLibraryMainCard<MetadataFieldDto>
                                title={t(MDLibraryModalTKeys.MDLibrary) + ' - ' + t(MDLibraryModalTKeys.Field) + 's'}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                onRowSelected={onRowSelected} />
                            <MDSideSheetCard
                                title={t(MDLibraryModalTKeys.Field) + ' ' + t(MDLibraryModalTKeys.Details)}
                                description={
                                    <Text className='md-group-details-description-text'>
                                        {`${ isFieldInAccount(selectedField?.name ?? '') ? `${t(MDLibraryModalTKeys.FieldExists)}` : ''}`}
                                    </Text>
                                }
                                dataId = 'md-field-side-sheet'
                                className='library-modal-body-card-right' > 
                                <MDFieldSideSheetContent
                                    mdField={selectedField as MetadataFieldDto} />
                            </MDSideSheetCard>
                        </Row>

                    </div>
            }}
            footer={{
                className: 'px-3 library-modal-footer',
                children:
                    <MdLibraryModalFooter
                        message={t(MDLibraryModalTKeys.FieldsAdded, { count: fieldsToAdd.length })}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        isProcessingSubmitState={[isProcessingSubmission, setIsProcessingSubmission]} />
            }}
            elementId={'MDLibraryFieldsModal'}
            isShowingState={isShowingState}
        />
    );
};