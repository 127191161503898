import React, { FC } from 'react';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { MDSideSheetInformationTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { MetadataGroupDecorDto } from '../../../../../api/mdGroups/mdGroupsApiTypes';
import { SidesheetPage } from '../../../../../components/SideSheet/SidesheetPage';
import { GroupDetailsNestedCollapse } from '../../../_groups/_groupDetails/GroupDetailsNestedCollapse';
import { MDGroupDetailsFieldPropertyBasic } from '../MdGroupDetailsFieldProperty/MdGroupDetailsFieldPropertyBasic';

interface MDGroupSideSheetBasicContentProps {
    mdGroup: MetadataGroupDecorDto;
    // refreshMDGroupsSentinalKeyState: [number, Dispatch<SetStateAction<number>>];
    onClose?: () => void;
}

export const MDGroupSideSheetBasicContent = ({ mdGroup, onClose }: MDGroupSideSheetBasicContentProps) => {
    // const [refreshMDGroupsSentinalKey, setRefreshMDGroupsSentinalKey] = refreshMDGroupsSentinalKeyState;

    const { t } = useSafeTranslation(TranslationFiles.MDSideSheetInformation);

    return !mdGroup || mdGroup.metadataFields.length == 0 ? (
        <div className='d-flex align-items-center justify-content-center h-50'>
            <Text>{t(MDSideSheetInformationTKeys.SelectGroup)}</Text>
        </div>
    ) : (
        <SidesheetPage title={mdGroup.name} onClose={onClose}>
            <div className='my-3 md-sidesheet-title-divider'></div>

            <GroupDetailsNestedCollapse openByDefault={true}>
                {mdGroup.metadataFields.map((field) => (
                    <MDGroupDetailsFieldPropertyBasic key={field.id} fieldName={field.allProperties.name} />
                ))}
            </GroupDetailsNestedCollapse>
        </SidesheetPage>
    );
};
