import React, { Dispatch, SetStateAction, useEffect, useState, useMemo, useCallback } from 'react';
import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import { Modal } from '@efilecabinet/nautilus-ui';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { Row } from 'reactstrap';
import { MDGroupSideSheetContent } from '../../../_management/_sidesheet/MdGroupSideSheetContent/MdGroupSideSheetContent';
import { MetadataGroupDecorDto, MetadataGroupDto } from '../../../../../api/mdGroups/mdGroupsApiTypes';
import { MetadataFieldDecorDto, MetadataFieldDto } from '../../../../../api/mdFields/mdFieldApiTypes';
import { useMetadataGroupUtilities } from '../../../../../hooks/metadata/useMetadataGroupUtilities';
import { useMetadataFieldUtilities } from '../../../../../hooks/metadata/useMetadataFieldUtilities';
import { useAuthContext } from '../../../../../app/_context/AuthContext';
import { useSafeTranslation, TranslationFiles, MDLibraryModalTKeys } from '../../../../../hooks/useSafeTranslation';
import { MDLibraryHeader } from '../__shared/MdLibraryHeader/MdLibraryHeader';
import { MDSideSheetCard } from '../../../_management/_sidesheet/MdSideSheetCard/MdSideSheetCard';
import { MDLibraryMainCard } from '../__shared/MdLibraryMainCard/MdLibraryMainCard';
import { useMDFieldApi } from '../../../../../api/mdFields/useMDFieldApi';
import { useMDGroupsApi } from '../../../../../api/mdGroups/useMDGroupsApi';
import { MdLibraryAddItemCta, MdLibraryAddItemCtaProps } from '../__shared/MdLibraryAddItemCta/MdLibraryAddItemCta';
import { MdLibraryModalFooter } from '../__shared/MdLibraryModalFooter/MdLibraryModalFooter';
import { useMDLibraryGroupsApi } from '../../../../../api/mdLibrary/useMDLibraryGroupsApi';
import '../__shared/MdLibraryModal.css';

export interface MDLibraryGroupsModalProps {
    isShowingState: [boolean, Dispatch<SetStateAction<boolean>>];
}

export const MDLibraryGroupsModal = ({ isShowingState }: MDLibraryGroupsModalProps) => {

    const { authUser, hasAuthUser } = useAuthContext();
    const { loadAllMdAccountGroupsAsync, loadAllMdLibraryGroupsAsync } = useMetadataGroupUtilities();
    const { loadAllMdAccountFieldsAsync } = useMetadataFieldUtilities();
    const { createMdFieldAsync } = useMDFieldApi();
    const { createMdGroupAsync } = useMDGroupsApi();
    const { getMdLibraryGroupAsync } = useMDLibraryGroupsApi();
    const { t } = useSafeTranslation(TranslationFiles.MDLibraryModal);

    // Backend data
    const [accountGroups, setAccountGroups] = useState<MetadataGroupDecorDto[]>();
    const [libraryGroups, setLibraryGroups] = useState<MetadataGroupDto[]>();
    const [accountFields, setAccountFields] = useState<MetadataFieldDecorDto[]>();

    // Local state
    const [selectedGroup, setSelectedGroup] = useState<MetadataGroupDto>();
    const [groupsToAdd, setGroupsToAdd] = useState<MetadataGroupDto[]>([]);

    // Modal state
    const [isProcessingSubmission, setIsProcessingSubmission] = useState(false);
    const [isShowing, setIsShowing] = isShowingState;

    const rowData: MetadataGroupDto[] | null = useMemo(() => {
        if (!libraryGroups) {
            return null;
        } else {
            return libraryGroups;
        }
    }, [libraryGroups]);

    const columnDefs: ColDef<MetadataGroupDto>[] = useMemo(() => {
        const addItemCtaProps: MdLibraryAddItemCtaProps = {
            isItemInAccount: isGroupInAccount,
            isItemAdded: isGroupAdded,
            addItem: addGroup,
            removeItem: removeGroup
        };

        return [
            {
                field: 'name',
                headerName: t(MDLibraryModalTKeys.NameColumn),
                flex: 3
            },
            {
                field: 'metadataFields',
                flex: 1,
                headerName: t(MDLibraryModalTKeys.NumOfFieldsColumn),
                valueFormatter: ({ value }) => (!!value ? value.length : 0),
                type: 'numericColumn'
            },
            {
                flex: 2,
                headerName: t(MDLibraryModalTKeys.AddToAccountColumn),
                cellRenderer: MdLibraryAddItemCta,
                cellRendererParams: addItemCtaProps,
                cellStyle: () => ({ display: 'flex', alignItems: 'center' }),
            },
        ];
    }, [libraryGroups, accountGroups, groupsToAdd]);

    function isFieldInAccount(name: string) {
        return !!accountFields?.find(field => field.name === name);
    }

    function isGroupInAccount(name: string) {
        return !!accountGroups?.find(group => group.name === name);
    }
    
    function isGroupAdded(id: string) {
        return !!groupsToAdd?.find(group => group.id === id);
    }
    
    function addGroup(id: string) {
        setGroupsToAdd([...groupsToAdd, libraryGroups?.find((group) => (group.id == id)) as MetadataGroupDecorDto]);
    }
    
    function removeGroup(id: string) {
        setGroupsToAdd(groupsToAdd.filter((group) => group.id !== id));
    }

    function onRowSelected(event: RowSelectedEvent<MetadataGroupDto>) {
        const rowData = event.data;
        if (!!rowData && event.node.isSelected()) {
            getMdLibraryGroupAsync(rowData.id).then((group) => (setSelectedGroup(group)));
        }
    }

    const onClose = () => setIsShowing(false);

    const onSubmit = useCallback(() => {
        if (!!authUser) {
            setIsProcessingSubmission(true);

            const updatedGroupsToAdd: MetadataGroupDto[] = [];
            const updatedGroupFieldsToAdd: MetadataFieldDto[] = [];

            groupsToAdd.forEach((group) => {
                getMdLibraryGroupAsync(group.id)
                    .then((group) => {
                        group.metadataFields.forEach((field) => {
                            if (!isFieldInAccount(field.allProperties.name)) {
                                updatedGroupFieldsToAdd.push({ ...field.allProperties, id: '', accountId: authUser.accountID.toString() });
                            }
                        });
                    });
                updatedGroupsToAdd.push({ ...group, id: '', accountId: authUser.accountID.toString() });
            });

            Promise.all([updatedGroupsToAdd.map((group) => createMdGroupAsync(group)), updatedGroupFieldsToAdd.map((field) => createMdFieldAsync(field))])
                .then(() => {
                    onClose();
                })
                .catch(() => {
                    // TODO: Need to have more robust error handling in Atlantis
                    console.error('Error creating groups');
                })
                .finally(() => {
                    setIsProcessingSubmission(false);
                });
        }
    }, [hasAuthUser]);

    function getSideSheetDescription() {
        if(!!selectedGroup && !!selectedGroup?.name) {
            return isGroupInAccount(selectedGroup?.name)
                ? <Text className='md-group-details-description-text'>
                    {t(MDLibraryModalTKeys.GroupExistsP1)}
                    <strong className='md-group-details-description-text-emphasis'>
                        {selectedGroup?.name}
                    </strong>
                    {t(MDLibraryModalTKeys.GroupExistsP2)}
                </Text>
                : <Text className='md-group-details-description-text'>
                    {t(MDLibraryModalTKeys.AddingGroupP1)}
                    <strong className='md-group-details-description-text-emphasis'>
                        {selectedGroup?.name}
                    </strong>
                    {t(MDLibraryModalTKeys.AddingGroupP2)}
                </Text>;
        } else {
            return null;
        }
    }

    useEffect(() => {
        if (!!authUser) {
            loadAllMdLibraryGroupsAsync().then((groups) => setLibraryGroups(groups));
            loadAllMdAccountGroupsAsync(authUser.accountID).then((groups) => setAccountGroups(groups));
            loadAllMdAccountFieldsAsync(authUser.accountID).then((fields) => setAccountFields(fields));
        }
    }, [hasAuthUser]);

    return (
        <Modal
            options={{ size: 'modal-xl', modalContentClassName: 'p-0' }}
            header={{
                className: 'p-0',
                children:
                    <MDLibraryHeader
                        title={t(MDLibraryModalTKeys.ModalTitle) + ' ' + t(MDLibraryModalTKeys.Group)}
                        handleClose={onClose} />
            }}
            body={{
                className: 'p-0',
                children: (
                    <div className='library-modal-body modal-body'>
                        <Row className='h-100 px-2 flex-nowrap'>
                            <MDLibraryMainCard<MetadataGroupDto>
                                title={t(MDLibraryModalTKeys.MDLibrary) + ' - ' + t(MDLibraryModalTKeys.Group) + 's'}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                onRowSelected={onRowSelected} />
                            <MDSideSheetCard
                                title={t(MDLibraryModalTKeys.Group) + ' ' + t(MDLibraryModalTKeys.Details)}
                                description={getSideSheetDescription()}
                                dataId='md-group-side-sheet'
                                className='library-modal-body-card-right' >
                                <MDGroupSideSheetContent 
                                    mdGroup={selectedGroup as MetadataGroupDecorDto}
                                    groupAdded={groupsToAdd.some(group => group.id === selectedGroup?.id)} />
                            </MDSideSheetCard>
                        </Row>
                    </div>
                ),
            }}
            footer={{
                className: 'px-3 library-modal-footer',
                children:
                    <MdLibraryModalFooter
                        message={t(MDLibraryModalTKeys.GroupsAdded, { count: groupsToAdd.length })}
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        isProcessingSubmitState={[isProcessingSubmission, setIsProcessingSubmission]} />
            }}
            elementId={'MDLibraryGroupsModal'}
            isShowingState={[isShowing, setIsShowing]}
        />
    );
};
