import React, { useMemo } from 'react';
import { TextButton } from '@efilecabinet/nautilus-ui';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { CustomCellRendererProps } from 'ag-grid-react';
import { useSafeTranslation, TranslationFiles, MDLibraryModalTKeys } from '../../../../../../hooks/useSafeTranslation';
import { MetadataFieldDto } from '../../../../../../api/mdFields/mdFieldApiTypes';
import { MetadataGroupDto } from '../../../../../../api/mdGroups/mdGroupsApiTypes';

export interface MdLibraryAddItemCtaProps extends Partial<CustomCellRendererProps<MetadataFieldDto | MetadataGroupDto>> {
    isItemInAccount: (name: string) => boolean;
    isItemAdded: (id: string) => boolean;
    addItem: (id: string) => void;
    removeItem: (id: string) => void;
}

export const MdLibraryAddItemCta = ({ data, isItemInAccount, isItemAdded, addItem, removeItem }: MdLibraryAddItemCtaProps) => {

    const { t } = useSafeTranslation(TranslationFiles.MDLibraryModal);

    const addItemLabel = useMemo(() => {
        return !!data && 'metadataFields' in data
            ? t(MDLibraryModalTKeys.AddGroupCta)
            : t(MDLibraryModalTKeys.AddFieldCta);
    }, [data]);

    return (
        <>
            {!!data &&
                (isItemInAccount(data.name)
                    ? <Text color='#696969' className='px-3'>{t(MDLibraryModalTKeys.InAccountCta)}</Text>
                    : isItemAdded(data.id)
                        ? <TextButton buttonType='textButton' label={t(MDLibraryModalTKeys.RemoveCta)} buttonStyle='btn-link' onClick={() => removeItem(data.id)} />
                        : <TextButton buttonType='textButton' label={addItemLabel} buttonStyle='btn-outline-primary' onClick={() => addItem(data.id)} />
                )
            }
        </>
    );
};