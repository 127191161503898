import React, { ReactNode } from 'react';
import { Card } from '@efilecabinet/efc-atlantis-components';
import { Col, Row } from 'reactstrap';
import './SideSheet.css';

interface SideSheetProps {
    hideTabs?: boolean;
    hideTabStyling?: boolean;
    children?: [tabs: ReactNode, tabContent: ReactNode];
}

export const SideSheet = (props: SideSheetProps) => {
    const { children, hideTabStyling, hideTabs } = props;

    return (
        <Card id={'sidesheet'} body fluid className={`h-100 py-0 ${!!hideTabStyling ? 'tab-no-border' : 'tab-card'}`}>
            <Row className='h-100'>
                <Col className={`h-100 p-0 ${!!hideTabStyling ? '' : 'tab-column'} ${!!hideTabs ? 'tab-hide' :'' }`}>
                    { /* Tabs */ }
                    {children?.[0]}
                </Col>
                <Col className='px-0 h-100 sidesheet-pane-col'>
                    { /* Tab Content */ }
                    {children?.[1]}
                </Col>
            </Row>
        </Card>
    );
};
