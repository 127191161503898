import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface MdGroupDetailsSkeletonLoaderProps {
    isBasic?: boolean;
}

export const MdGroupDetailsSkeletonLoader = ({ isBasic = false } : MdGroupDetailsSkeletonLoaderProps) => {

    function getDetailsHeight(){
        return isBasic ? 20 : 40;
    }

    return (
        <div>
            <Skeleton height={20} width={`80%`} style={{ marginTop: '0.4rem' }} />
            <Skeleton height={getDetailsHeight()} width={`90%`} style={{ marginTop: '0.6rem', marginLeft: '0.25rem', marginBottom: '1rem' }} />
            <Skeleton height={getDetailsHeight()} width={`90%`} style={{ marginLeft: '0.25rem', marginBottom: '1rem' }} />
            <Skeleton height={getDetailsHeight()} width={`90%`} style={{ marginLeft: '0.25rem', marginBottom: '1rem' }} />
            <Skeleton height={getDetailsHeight()} width={`90%`} style={{ marginLeft: '0.25rem', marginBottom: '1rem' }} />
            <Skeleton height={getDetailsHeight()} width={`90%`} style={{ marginLeft: '0.25rem', marginBottom: '1rem' }} />
            <Skeleton height={getDetailsHeight()} width={`90%`} style={{ marginLeft: '0.25rem', marginBottom: '1rem' }} />
        </div>
    );
};
