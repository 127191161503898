import React, { Dispatch, useMemo, SetStateAction, useEffect, useState } from 'react';
import { Table } from '@efilecabinet/nautilus-ui';
import { ColDef, RowSelectedEvent } from 'ag-grid-community';
import { TranslationFiles, useSafeTranslation, MDGroupsTKeys, MetadataTKeys } from '../../../../hooks/useSafeTranslation';
import { useAuthContext } from '../../../../app/_context/AuthContext';
import { useMDGroupsApi } from '../../../../api/mdGroups/useMDGroupsApi';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { MDManagementZeroState } from '../../_management/MdManagementZeroState/MdManagementZeroState';
import { MDGroupModal } from '../_modal/MdGroupModal/MdGroupModal';
import { MetadataGroupDecorDto } from '../../../../api/mdGroups/mdGroupsApiTypes';
import { useMetadataGroupUtilities } from '../../../../hooks/metadata/useMetadataGroupUtilities';

interface MdGroupsManagementTableProps {
    active: boolean;
    refreshGroupsSentinalKeyState: [number, Dispatch<SetStateAction<number>>];
    selectedGroupState: [MetadataGroupDecorDto, Dispatch<SetStateAction<MetadataGroupDecorDto>>];
}

export const MdGroupsManagementTable = ({ active, refreshGroupsSentinalKeyState: [refreshGroupsSentinalKey, setRefreshGroupsSentinalKey], selectedGroupState : [selectedGroup, setSelectedGroup]}: MdGroupsManagementTableProps) => {

    const { authUser, hasAuthUser } = useAuthContext();
    const { openModal } = useLayerContext();
    const { deleteMdGroupAsync } = useMDGroupsApi();
    const { loadAllMdAccountGroupsAsync } = useMetadataGroupUtilities();
    const { t } = useSafeTranslation(TranslationFiles.MDGroups);

    const [accountGroups, setAccountGroups] = useState<MetadataGroupDecorDto[]>();

    const rowData: MetadataGroupDecorDto[] | null = useMemo(() => {
        if (!accountGroups) {
            return null;
        } else {
            return accountGroups;
        }
    }, [accountGroups]);

    const columnDefs: ColDef<MetadataGroupDecorDto>[] = useMemo(() => {
        return [
            {
                field: 'name',
                headerName: t(MDGroupsTKeys.GroupNameColumn),
                flex: 3
            },
            {
                field: 'appliedInstanceCount',
                headerName: t(MDGroupsTKeys.InstanceColumn),
                flex: 2,
                type: 'numericColumn',
                valueFormatter: ({ value }) => (value ?? 0),
            },
            {
                field: 'createdByDisplayName',
                headerName: t(MDGroupsTKeys.CreatedByColumn),
                flex: 2,
                valueFormatter: ({ value }) => (value ?? '-'),
            },
            // TODO: Implement context menu
        ];
    }, []);
    
    function openGroupModal(group?: MetadataGroupDecorDto) {
        openModal(() => <MDGroupModal mdGroupToEdit={group} onSubmitGroup={() => setRefreshGroupsSentinalKey(prevState => prevState + 1)} /> );
    }
    
    function onEdit(group: MetadataGroupDecorDto) {
        openGroupModal(group);
    }

    function onDelete(group: MetadataGroupDecorDto) {
        setAccountGroups(accountGroups?.filter((g) => g.id !== group.id));
        deleteMdGroupAsync(group.id);
    }

    function onRowSelected(event: RowSelectedEvent<MetadataGroupDecorDto>) {
        const rowData = event.data;
        if (!!rowData && event.node.isSelected()) {
            setSelectedGroup(rowData);
        }
    }

    useEffect(() => {
        if (!!active && !!authUser) {
            loadAllMdAccountGroupsAsync(authUser.accountID).then((groups) => (setAccountGroups(groups)));
        }
    }, [hasAuthUser, refreshGroupsSentinalKey, active]);

    return (
        <>
            {/* TODO: When we implement custom zero states for the Nautilus table, move the zero state into the Table */}
            {rowData?.length === 0
                ? <MDManagementZeroState buttonText={t(MetadataTKeys.CreateGroup)} onButtonClick={() => openGroupModal()} />
                : <Table<MetadataGroupDecorDto>
                    height='600px'
                    columnDefs={columnDefs}
                    rowData={rowData}
                    onRowSelected={onRowSelected}
                    showSearchBar={true}
                    rowSelection={{ mode: 'singleRow' }} />
            }
        </>
    );
};