import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { MetadataFieldDto } from '../mdFields/mdFieldApiTypes';

export const useMDLibraryFieldsApi = () => {

    const getMdLibraryFieldAsync = async (id: string): Promise<MetadataFieldDto> => {
        const resp: AxiosResponse = await Api.get(`api/metadataLibraryField/${id}`);
        return resp.data as MetadataFieldDto;
    };

    const queryMdLibraryFieldsAsync = async (start: number, count: number): Promise<MetadataFieldDto[]> => {
        const resp: AxiosResponse = await Api.get(`api/metadataLibraryField/${start}/${count}`);
        return resp.data as MetadataFieldDto[];
    };

    return {
        getMdLibraryFieldAsync,
        queryMdLibraryFieldsAsync,
    };
};