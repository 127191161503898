import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { ResizableCol, Tab, Tabs } from '@efilecabinet/efc-atlantis-components';
import { Tooltip } from '@efilecabinet/nautilus-ui';
import { Col } from 'reactstrap';
import { useResizableColUtilities } from '../../../../../hooks/useResizableColUtilities';
import { MetadataGroupDecorDto } from '../../../../../api/mdGroups/mdGroupsApiTypes';
import { MetadataFieldDto } from '../../../../../api/mdFields/mdFieldApiTypes';
import { SelectedDetail } from '../../../../_routing/routingTypes';
import { SideSheet } from '../../../../../components/SideSheet/SideSheet';
import { SideSheetTabButton } from '../../../../../components/SideSheet/SideSheetButton';
import { MDFieldSideSheetContent } from '../MdFieldSideSheetContent/MdFieldSideSheetContent';
import { MDGroupSideSheetBasicContent } from '../MdGroupSideSheetBasicContent/MdGroupSideSheetBasicContent';
import './MdManagementSideSheet.css';

interface MDManagementSideSheetProps {
    selectedMDField?: MetadataFieldDto;
    selectedMDGroup?: MetadataGroupDecorDto;
    currentRoute: string;
    refreshMDFieldsSentinalKeyState: [number, Dispatch<SetStateAction<number>>];
    refreshMDGroupsSentinalKeyState: [number, Dispatch<SetStateAction<number>>];
}

export const MDManagementSideSheet: FC<MDManagementSideSheetProps> = ({ selectedMDField, selectedMDGroup, currentRoute, refreshMDGroupsSentinalKeyState }) => {
    const [sideSheetIsOpen, setSideSheetIsOpen] = useState<boolean>(false);

    const { MIN_EXPANDED_WIDTH, onSidesheetCollapse, onSidesheetExpand,
        handleSideSheetResize, getSideSheetEnabled, getSideSheetWidth } = useResizableColUtilities();

    const collapseSideSheet = () => {
        setSideSheetIsOpen(false);
        onSidesheetCollapse();
    };

    const expandSideSheet = () => {
        setSideSheetIsOpen(true);
        onSidesheetExpand();
    };

    const handleTabClick = () => {
        if (!!sideSheetIsOpen) {
            collapseSideSheet();
            return;
        }

        expandSideSheet();
    };

    useEffect(() => {
        if (!sideSheetIsOpen) {
            expandSideSheet();
        }
    }, [selectedMDField, selectedMDGroup]);

    useEffect(() => { collapseSideSheet(); }, []);

    return (
        <>
            <Col className={`${!sideSheetIsOpen ? ' metadata-tab-hide' : ''} metadata-column-divider`} />

            <ResizableCol xs='auto' direction='left' className='h-100 pe-0 sidesheet-col' width={getSideSheetWidth()} minWidth={MIN_EXPANDED_WIDTH} canResize={false}>

                <SideSheet hideTabStyling={true} hideTabs={!!sideSheetIsOpen}>
                    <Tabs vertical>
                        <Tab className='md-sidesheet-tab'>
                            <Tooltip title={`${currentRoute == '/metadata/fields' ? 'Field Details' : 'Group Details'}`}>
                                <SideSheetTabButton dataId={`sideSheet-tab-details ${!!sideSheetIsOpen ? 'metadata-tab-hide' : ''}`} icon={{ icon: ['far', 'circle-info'] }} isActive={!!sideSheetIsOpen} onClick={() => handleTabClick()} />
                            </Tooltip>
                        </Tab>
                    </Tabs>

                    <Tab.Content dataId='sideSheet-pane' className="h-100 pt-5 ps-3">
                        {currentRoute == '/metadata/fields'  && !!sideSheetIsOpen && <MDFieldSideSheetContent onClose={collapseSideSheet} mdField={selectedMDField as MetadataFieldDto} />}
                        {currentRoute == '/metadata/groups' && !!sideSheetIsOpen && <MDGroupSideSheetBasicContent onClose={collapseSideSheet} mdGroup={selectedMDGroup as MetadataGroupDecorDto} />}
                    </Tab.Content>
                </SideSheet>
            </ResizableCol>
        </>
    );
};
