import React, { FC, ReactNode } from 'react';
import { Col, Row } from 'reactstrap';
import { Button, Card, Text } from '@efilecabinet/efc-atlantis-components';
import './MdSideSheetCard.css';

export interface MDSideSheetCardProps {
    title: string;
    children?: ReactNode;
    description?: ReactNode;
    isHidden?: boolean;
    onClose?: () => void;
    dataId?: string;
    className?: string;
}

export const MDSideSheetCard = ({ title, children, description, isHidden = false, onClose, dataId, className } : MDSideSheetCardProps) => {
    return (
        <div className={`${className ?? ''} h-100`}>
            <Col className='h-100 md-sidesheet-col-scroll'>
                <Card body fluid borderless className='h-100 py-0' dataId={dataId} hidden={isHidden}>

                    <Col className='d-flex flex-column'>
                        <Row className='flex-grow-1'>{children}</Row>
                        <Row>{!!description && description}</Row>
                    </Col>
                </Card>
            </Col>
        </div>
    );
};
