import React, { useState } from 'react';
import { AdditionalFeedback } from '../../../../../../api/smartData/smartDataApiTypes';
import { IUseModalProps } from '../../../../../../hooks/useModal';
import { useForm } from '../../../../../../hooks/useForm';
import { SmartDataTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../hooks/useSafeTranslation';
import { SmartDataItemFeedback, FeedbackEnum } from '../../SmartDataItemFeedback/SmartDataItemFeedback';
import { Modal, EfcCallToActionInfo, FormGroup, Input, Label } from '@efilecabinet/efc-atlantis-components';
import './ExtractedValueModal.css';

interface ExtractedValueModalProps extends IUseModalProps {
    extractedValueId: string;
    isFullTextFeedback: boolean;
    feedbackValue: FeedbackEnum;
    updateFeedback: (iconType: FeedbackEnum, additionalFeedback?: AdditionalFeedback) => void;
    onClose: (isConfirmed: boolean) => void;
}

export const ExtractedValueModal = ({ extractedValueId, isFullTextFeedback, feedbackValue, updateFeedback, onClose }: ExtractedValueModalProps) => {
    const { t } = useSafeTranslation(TranslationFiles.SmartData);

    const [feedback, setFeedback] = useState<FeedbackEnum | undefined>(feedbackValue);

    const initFeedbackForm: AdditionalFeedback = {
        whatShouldBe: '',
        whatIsMissing: '',
        unrequested: ''
    };

    const feedbackForm = useForm<AdditionalFeedback>(initFeedbackForm);

    const handleSubmit = () => {
        const additionalFeedback: AdditionalFeedback = {
            whatShouldBe: feedbackForm.model.whatShouldBe,
            whatIsMissing: feedbackForm.model.whatIsMissing,
            unrequested: feedbackForm.model.unrequested
        };

        updateFeedback(feedback!, additionalFeedback);
        onClose(false);
    };

    const showFormFillSaveCtas: EfcCallToActionInfo[] = [
        { text: t(SmartDataTKeys.Submit), emphasis: 'med', color: 'primary', onClick: handleSubmit },
    ];

    return (
        <Modal
            isOpen={true}
            toggle={() => onClose(false)}
            ctas={showFormFillSaveCtas}
            title={t(SmartDataTKeys.FeedbackModalTitle)}>
            <Modal.Body>
                <div className='pb-3'>{t(SmartDataTKeys.FeedbackModalDescription)}</div>
                <SmartDataItemFeedback extractedValueId={extractedValueId} canOpenModal={false} isFullTextFeedback={isFullTextFeedback} feedbackValue={feedbackValue} onFeedbackClick={(iconType: FeedbackEnum) => setFeedback(iconType)} />

                {feedback != FeedbackEnum.Accurate && (isFullTextFeedback
                    ? <div className='mt-4'>
                        <FormGroup>
                            <Label for='whatIsMissing' className='mb-1'>{t(SmartDataTKeys.FeedbackModalMissing)}</Label>
                            <Input className='mt-1' type='text' name='whatIsMissing' value={feedbackForm.model.whatIsMissing} onChange={feedbackForm.onPropChanged} invalid={!!feedbackForm.errors?.whatIsMissing} />
                        </FormGroup>

                        <FormGroup>
                            <Label for='unrequested' className='mb-1'>{t(SmartDataTKeys.FeedbackModalUnrequested)}</Label>
                            <Input className='mt-1' type='text' name='unrequested' value={feedbackForm.model.unrequested} onChange={feedbackForm.onPropChanged} invalid={!!feedbackForm.errors?.unrequested} />
                        </FormGroup>
                    </div>

                    : <FormGroup className='mt-4'>
                        <Label for='whatShouldBe' className='mb-1'>{t(SmartDataTKeys.FeedbackModalShouldBe)}</Label>
                        <Input className='mt-1' type='text' name='whatShouldBe' value={feedbackForm.model.whatShouldBe} onChange={feedbackForm.onPropChanged} invalid={!!feedbackForm.errors?.whatShouldBe} />
                    </FormGroup>)
                }

            </Modal.Body>
        </Modal>
    );
};